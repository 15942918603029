import React, { useState, useEffect, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Modal, Button, Form, Container, Table } from "react-bootstrap"; // Import Bootstrap components
import ReactPaginate from "react-paginate"; // Import pagination component
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import constants from "../../services/constants";
import {
  getData,
  createData,
  UpdateData,
  deleteData,
} from "./../../services/routes.services";
import { AuthContext } from "../../context/AuthContext";
import parse from "html-react-parser";

const Blog = () => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [content, setContent] = useState("");
  const [shortContent, setShortContent] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // Track index for editing
  const [images, setImages] = useState(null); // Initialize with null for image file
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [showViewModal, setShowViewModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [blogToView, setBlogToView] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const rowsPerPage = 10; // Items per page
  const { user } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Assuming token is stored in `user.token`
    },
  };

  useEffect(() => {
    fetchRows();
  }, []);

  const fetchRows = async () => {
    try {
      const response = await getData("blogs"); // Adjust the URL to your API endpoint
      setRows(response.data); // Assuming response.data contains the rows
    } catch (error) {
      console.error("Error fetching rows:", error);
    }
  };
  const validateForm = () => {
    const errors = {};

    if (title.trim() === "") errors.title = "Title is required";
    if (shortContent.trim() === "")
      errors.shortContent = "Short Content is required";
    if (content.trim() === "") errors.content = "Content is required";
    if (images === "") errors.image = "Image is required";

    if (formSubmitted) {
      setErrors(errors);
    }

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };
  const handleAddRow = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!validateForm()) return; // If validation fails, stop form submission
    if (title && content) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);
      formData.append("createdAt", publishDate);
      formData.append("shortContent", shortContent);
      // Check if there is an image file to upload
      if (images) {
        formData.append("images", images); // Append the file
      }

      try {
        if (editIndex !== null) {
          console.log(editIndex)
          // Update existing row
          await UpdateData(`blogs/${editIndex._id}`, formData, config); // Adjust for your PUT endpoint

          const updatedRows = [...rows];
          updatedRows[editIndex] = { ...updatedRows[editIndex], ...formData }; // Update the local state
          // setRows(response.data.courses);
          fetchRows();
          toast.success("Blog updated successfully!");
        } else {
          // Add new row
          const response = await createData("blogs", formData, config);
          const newRow = response.data; // Axios returns data in response.data
          setRows([...rows, newRow]);
          fetchRows();
          toast.success("Blog added successfully!");
        }
        resetForm();
      } catch (error) {
        console.error("Error saving row:", error);
        toast.error("Error saving blog!");
      }
    }
  };

  const resetForm = () => {
    setTitle("");
    setPublishDate("");
    setContent("");
    setShortContent("");
    setImages(null); // Reset the images state
    setImagePreview(null);
    setEditIndex(null);
    setIsFormOpen(false);
  };

  const handleEditRow = (index) => {
    const row = index;
    setTitle(row.title);
    setPublishDate(
      row.createdAt ? new Date(row.createdAt).toISOString().split("T")[0] : ""
    );
    setContent(row.content);
    setShortContent(row.shortContent);
    setImages(row.imageUrl); // If you have a URL for images
    setEditIndex(index);
    setIsFormOpen(true);
  };

  const handleClose = () => {
    resetForm();
    setErrors({});
  };

  const handleDeleteRow = async (index) => {
    const rowToDelete = index;
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${rowToDelete.title}"?`
    );
    if (confirmDelete) {
      try {
        const newRows = await deleteData(`blogs/${rowToDelete._id}`, config);
        fetchRows();
        toast.success("Blog deleted successfully!"); // Show success toaster
      } catch (error) {
        toast.error("Error deleting row!"); // Show error toaster
        console.error("Error deleting row:", error);
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImages(file); // Store the image file
      setImagePreview(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    return () => {
      // Revoke preview URL when component unmounts
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);
  function truncateText(text, maxLength) {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }
  useEffect(() => {
    setErrors({});
    return () => {
      // Revoke preview URL when component unmounts
      validateForm();
    };
  }, [title, content, shortContent, images]);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Update the current page when the user clicks
  };
  // Toggle blog status (publish/unpublish)
  const toggleBlogStatus = async (blogId) => {
    try {
      //await axios.put(`http://localhost:5000/api/blogs/${blogId}/status`, {}, config);
      await UpdateData(`blogs/${blogId}/status`, {}, config);
      fetchRows(); // Refresh blogs after status change
      toast.success("Blog status updated successfully!"); // Show success toaster
    } catch (error) {
      console.error("Error toggling blog status:", error);
    }
  };
  const handlePreviewClose = () => {
    setShowViewModal(false);
  };

  const handleViewBlog = (blog) => {
    setBlogToView(blog);
    setShowViewModal(true);
  };
  // Pagination logic
  const offset = currentPage * rowsPerPage;
  const currentPageData = rows.slice(offset, offset + rowsPerPage);
  const pageCount = Math.ceil(rows.length / rowsPerPage);

  return (
    <Container className="mt-4">
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setIsFormOpen(true)}
      >
        Add Blog
      </Button>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />

      <Modal show={isFormOpen} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {editIndex !== null ? "Edit Blog" : "Add New Blog"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddRow}>
            <Form.Group className="mb-3">
              <Form.Label>Blog Title:</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {errors.title && <p style={{ color: "red" }}>{errors.title}</p>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Short Content:</Form.Label>
              <ReactQuill
                theme="snow"
                value={shortContent}
                onChange={setShortContent}
              />
              {errors.shortContent && (
                <p style={{ color: "red" }}>{errors.shortContent}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Blog Content:</Form.Label>
              <ReactQuill theme="snow" value={content} onChange={setContent} />
              {errors.content && (
                <p style={{ color: "red" }}>{errors.content}</p>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Publish Date</Form.Label>
              <Form.Control
                type="date"
                name="createdAt"
                value={publishDate}
                onChange={(e) => setPublishDate(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Upload Blog Image:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
              {imagePreview && (
                <div>
                  <img
                    src={imagePreview}
                    alt={"Image Preview"}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              )}
              {!imagePreview && images && (
                <img
                  src={`${constants.File_BASE_URL}uploads/${images}`}
                  alt={`Preview`}
                  style={{ width: "20%", marginTop: "10px" }}
                />
              )}
              {errors.image && <p style={{ color: "red" }}>{errors.image}</p>}
            </Form.Group>
            <Button type="submit" variant="success">
              {editIndex !== null ? "Update" : "Submit"}
            </Button>
            <Button variant="secondary" onClick={handleClose} className="ms-2">
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th style={{ width: "3%" }}>#</th>
            <th style={{ width: "24%" }}>Blog Title</th>
            <th style={{ width: "21%" }}>Short Content</th>
            <th style={{ width: "10%" }}>Status</th>
            <th style={{ width: "12%" }}>Published Date</th>
            <th style={{ width: "30%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((row, index) => (
            <tr key={row._id}>
              <td>{offset + index + 1}</td>
              <td>{row.title}</td> {/* Fixed to use row.title */}
              <td dangerouslySetInnerHTML={{ __html: truncateText(row.shortContent ,50)}}></td>
              <td>{row.status === 0 ? "UnPublished" : "Published"}</td>
              <td>{formatDate(row.createdAt)}</td>
              <td>
               
                <Button variant="warning" onClick={() => handleEditRow(row)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteRow(row)}
                  className="ms-2"
                >
                  Delete
                </Button>
                {"     "}
                <Button onClick={() => toggleBlogStatus(row._id)}>
                  {row.status === 1 ? "Unpublish" : "Publish"}
                </Button>{" "}
                <Button variant="primary" onClick={() => handleViewBlog(row)}>
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showViewModal} onHide={handlePreviewClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Blog Title : {blogToView && blogToView.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {blogToView !== null ? (
            <Table striped bordered hover>
              <thead>
                {/* <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr> */}
              </thead>
              <tbody>
                <tr>
                  <td>Short Content</td>
                  <td>{parse(`${blogToView.shortContent}`)}</td>
                </tr>
                <tr>
                  <td>Content</td>
                  <td>{parse(`${blogToView.content}`)}</td>
                </tr>
                <tr>
                  <td>Publish Date</td>
                  <td>{formatDate(blogToView.createdAt)}</td>
                </tr>

                <tr>
                  <td>Blog Status</td>
                  <td>
                    {blogToView.status === 0 ? "UnPublished" : "Published"}
                  </td>
                </tr>
                <tr>
                  <td>Blog Image</td>
                  <td>
                    <img
                      src={`${constants.File_BASE_URL}uploads/${blogToView.imageUrl}`}
                      alt={`Preview`}
                      style={{ width: "100%", marginTop: "10px" }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <p>No blog details available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePreviewClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination Component */}
      {rows.length > rowsPerPage && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      )}
    </Container>
  );
};

export default Blog;
