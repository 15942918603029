import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import constants from "../services/constants";
import axios from "axios";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Stores user data including role
  const navigate = useNavigate();

  useEffect(() => {
    // Check for a stored token and role on initial load
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (token && role) {
      setUser({ token, role });
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${constants.API_BASE_URL}auth/login`, {
        email,
        password,
      });
      const { token, role } = response.data;

      // Store token and role in local storage
      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      setUser({ token, role });

      // Redirect based on role
      if (response.data.role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error(
        "Login failed:",
        error.response?.data?.message || error.message
      );
    }
  };

  const logout = () => {
    // Clear user data from state and localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    setUser(null);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
