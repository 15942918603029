import React, { useState, useEffect } from "react";
import Styles from "../Styles/Common.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import constants from "../../services/constants";
import parse from "html-react-parser";
import { getData } from "../../services/routes.services";
import Loader from "../Common/PageLoader/Loader";

const formatDate = (dateString) => {
  if (!dateString || isNaN(new Date(dateString).getTime())) {
    return "Date not available";
  }
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);
};

function WebinarsDetail() {
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const location = useLocation();
  const navigate = useNavigate();
  //const { title, time, date, images, scaner, description, webinarDetail } = location.state || {};
  const [webinarsData, setWebinarsData] = useState([]);
  const currentPageUrl = location.pathname;
  const updatedUrl = currentPageUrl.replace("/webinars-detail/", "");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData(`webinar/${updatedUrl}`)
      .then((res) => {
        setWebinarsData(res.data.webinars[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  //console.log(webinarsData)
  const webinarDate = new Date(webinarsData.startdate);
  const currentDate = new Date();
  if (loading) {
    return <Loader />;
  }
  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
  };

  const handleRegisterClick = () => {
    navigate("/session-book", { state: { focus: true } });
  };
  return (
    <div className={Styles.webinarsPage + " " + Styles.topSpace}>
      <div className={Styles.brownBg}>
        <section className={Styles.BreadcrumbStyle}>
          <Container>
            <Row>
              <Col>
                <ul>
                  <li>
                    <Link to="/" title="Home">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/webinars" title="webinars">
                      Webinars
                    </Link>
                  </li>
                  <li>{webinarsData.title}</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <div className={Styles.webinarDetail}>
        <Container>
          <Row>
            <Col>
              <div className={Styles.webDetailContainer}>
                <h3 className={Styles.centerTitle}>
                  <span>Webinars</span>
                </h3>
                <div className={Styles.registerNow}>
                  <ul className={Styles.webTime}>
                    <li>
                      <img
                        src={require("../../assest/images/clock_icon.png")}
                        alt="Time"
                      />
                      Time <span>{webinarsData.time} onwards</span>
                    </li>
                    <li>
                      <img
                        src={require("../../assest/images/data_icon.png")}
                        alt="Date"
                      />
                      Date <span>{formatDate(webinarsData.startdate)}</span>
                    </li>
                  </ul>
                  {currentDate >= webinarDate ? (
                    <Link
                      to="/upcoming-webinar"
                      state={{ webinarDetail: webinarsData }}
                    >
                      Register Now <IoIosArrowRoundForward />
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <div className={Styles.webImg}>
                  <figure className={Styles.mainImg}>
                    <img
                      src={constants.Image_BASE_URL + "" + webinarsData.images}
                      alt=""
                      onLoad={handleImageLoad}
                      width={imageDimensions.width}
                      height={imageDimensions.height}
                    />
                  </figure>
                  <figure className={Styles.scanerImg}>
                    <img
                      src={
                        constants.Image_BASE_URL +
                        "" +
                        webinarsData.detailimages
                      }
                    />
                  </figure>
                </div>
                {parse(`${webinarsData.description}`)}
                {currentDate <= webinarDate ? (
                  <Link
                    to="/upcoming-webinar"
                    state={{ webinarDetail: webinarsData }}
                  >
                    Register Now <IoIosArrowRoundForward />
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default WebinarsDetail;
