import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import constants from "../../services/constants";
import parse from 'html-react-parser';
import { getData } from "../../services/routes.services";
import { IoIosArrowRoundForward } from "react-icons/io";
import Loader from "../Common/PageLoader/Loader";

const formatDate = (dateString) => {
    if (!dateString) return "Unknown date";
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid date";
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);
  };
function BlogDetailComponent (){
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    //const { title, bannerImg, date, content } = location.state || {};
    const [blogData, setBlogData] = useState([]);
    const [recentBlogs, setRecentBlogs] = useState([]);
    const location = useLocation();
    const currentPageUrl = location.pathname;
    const updatedUrl = currentPageUrl.replace("/blogDetail/", "");
    const [loading, setLoading] = useState(true);
    //Image Size Loader
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    useEffect(() => {
        getData(`blogs/${updatedUrl}`)
          .then((res) => {
            if (res.data?.blogs?.length > 0) {
              setBlogData(res.data.blogs[0]);
              setLoading(false);
            }
          })
          .catch((error) => console.error("Error fetching blog data:", error));
      }, []);
    const fetchRows = async () => {
        try {
            const response = await getData('blogs');
            const data = response.data;
            setRecentBlogs(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching rows:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchRows();
    }, []);
    if(loading){
        return <Loader />
    }
    // var recentBlog = blogData.filter(item => item.title !== title);
    const baseUrl = window.location.href; // Full URL (e.g., https://example.com/page?param=value)

   

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(baseUrl)}`;
  const instagramShareUrl = `https://www.instagram.com`; // Instagram doesn't have direct sharing URLs
  const youtubeShareUrl = `https://www.youtube.com`; // YouTube doesn't support direct sharing for URLs
  
    const TextLimit = 130;
    var recentBlog = recentBlogs.filter(item => item.title !== blogData.title);
    return(
        <div className={Styles.blogPage + " " + Styles.topSpace}>
            <div className={Styles.brownBg}>
                <section className={Styles.BreadcrumbStyle}>
                    <Container>
                        <Row>
                            <Col>
                                <ul>
                                    <li><Link to='/' title="Home">Home</Link></li>
                                    <li><Link to='/blogs' title="Blogs">Blogs</Link></li>
                                    <li>{blogData.title}</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <div className={Styles.blogDetail}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className={Styles.centerTitle}><span>Blogs</span></h3>
                        </Col>
                    </Row>
                </Container>
                <div className={Styles.blogContent}>
                    <figure className={Styles.blogDetailImg}>
                        <img src={constants.Image_BASE_URL+""+blogData.imageUrl} alt=""  onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                    </figure>
                    <Container>
                        <Row>
                            <Col>
                                <div className={Styles.contentBox}>
                                    <h6>{blogData.title}</h6>
                                    {parse(`${blogData.content}`)}
                                    <div className={Styles.postDetail}>
                                        <span>{formatDate(blogData.createdAt)}</span>
                                        <ul>
                                            <li>
                                            <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer"><img src={require('../../assest/images/share_icon.png')} alt="" /></a>
                                            </li>
                                            <li>
                                            <a href={instagramShareUrl} target="_blank" rel="noopener noreferrer"><img src={require('../../assest/images/insta_icon.webp')} alt="" /></a>
                                            </li>
                                            <li>
                                            <a href={youtubeShareUrl} target="_blank" rel="noopener noreferrer"><img src={require('../../assest/images/youtube_icop.webp')} alt="" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={Styles.blogsBlock}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className={Styles.centerTitle}>Recent <span>Blogs</span></h3>
                        </Col>
                    </Row>
                    <Row>
                    {recentBlog &&
                            recentBlog.slice(0, 3).map((item, index)=>{
                                return(
                                    <Col md={4} key={index} className={Styles.bolgWidth}>
                                        <div className={Styles.blogList}>
                                            <figure>
                                                <img src={constants.Image_BASE_URL+""+item.imageUrl} alt={item.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                            </figure>
                                            <div className={Styles.content}>
                                                <h6>{item.title}</h6>
                                                {parse(`${item.shortContent.length > TextLimit ? item.shortContent.substring(0, TextLimit) + "..." : item.shortContent}`)}
                                                <div className={Styles.blogTime}>
                                                    <span>{formatDate(item.createdAt)}</span>
                                                    <Link to={{ pathname: `/blogDetail/${item.link}`, }}  title={item.title}><IoIosArrowRoundForward /></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default BlogDetailComponent;
