import axiosService from "./AxiosConfig";

// Admin Backend  Api
export const getData = (url) => {
  //Hader Section
  return axiosService.get(url);
};
// Admin Backend  Api
export const getDataAuth = (url, config) => {
  //Hader Section
  return axiosService.get(`${url}`, config);
};
export const fetchCsrfToken = (url) => {
  //Hader Section
  return axiosService.get(`${url}`);
};

export const createData = (url, postData, config) => {
  return axiosService.post(`${url}`, postData, config);
};

export const SubmiteDataSession = (url, postData, config) => {
  return axiosService.post(`${url}`, postData, config);
};

export const UpdateData = (url, postData, config) => {
  return axiosService.put(`${url}`, postData, config);
};
export const deleteData = (url, config) => {
  return axiosService.delete(`${url}`, config);
};

export const getHeader = () => {
  // Header API
  return axiosService.get("header");
};

export const getBanner = () => {
  // Home
  return axiosService.get("banner");
};

export const getFounder = () => {
  // Founder API
  return axiosService.get("founder");
};

export const getOurCourse = () => {
  // Our Courses API
  return axiosService.get("course");
};

export const getWebinars = () => {
  //// Webinars API
  return axiosService.get("webinar");
};

export const getLegacy = () => {
  // Legacy API
  return axiosService.get("legacy");
};

export const getCommunity = () => {
  // Legacy API
  return axiosService.get("community");
};

export const getSocialMedia = () => {
  // Social Media API
  return axiosService.get("socialmedia");
};

export const getOurTeam = () => {
  // Our Team API
  return axiosService.get("teams");
};