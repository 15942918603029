
import { configureStore } from '@reduxjs/toolkit';
import headerReducer from '../Components/Common/Header/HeaderSlice'
const store = configureStore({
  reducer: {
    header: headerReducer,
  },
});

export default store;
