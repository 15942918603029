import React, { useEffect, useState } from "react";
import BannerComponents from "./Banner";
import FounderComponent from "./Founder";
import WatchVideoComponents from "./WatchVideo";
import OurCoursesComponents from "./OurCourses";
import WebinarsComponents from "./Webinars";
import KidsCornerComponents from "./KidsCorner";
import LegacyComponents from "./Legacy";
import AnantaCommunityComponents from "./AnantaCommunity";
import SpreadingIdeasComponents from "./SpreadingIdeas";
import { getBanner, getOurCourse, getSocialMedia, getFounder, getWebinars, getLegacy, getCommunity } from "../../services/routes.services";
import Styles from '../Styles/Common.module.css';
import Loader from "../Common/PageLoader/Loader";

function HomeRoute(){
    const [bannerData, setBannerData] = useState([]);
    const [course, setCourse] = useState([]);
    const [media, setMedia] = useState([]);
    const [founder, setFounder] = useState([]);
    const [webinars, setWebinars] = useState([]);
    const [leagcy, setLegacy] = useState([]);
    const [communityData, setCommunityData] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        Promise.all([
            getBanner(),
            getOurCourse(),
            getSocialMedia(),
            getFounder(),
            getWebinars(),
            getLegacy(),
            getCommunity(),
        ]).then(([bannerRes, courseRes, mediaRes, founderRes, webinarsRes, leagcyRes, communityRes]) => {
            setBannerData(bannerRes.data.banners);
            setCourse(courseRes.data.courses);
            setMedia(mediaRes.data.Socialmedia);
            setFounder(founderRes.data.Founders[0]);
            setWebinars(webinarsRes.data.webinars)
            setLegacy(leagcyRes.data.legacys)
            setCommunityData(communityRes.data.communitys)
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }, []);
    if(loading){
        return <Loader />
    }
    if(bannerData.length === 0){
        return null;
    }
    //console.log(webinars)
    return(
        <div className={Styles.topSpace}>
            <BannerComponents bannerData={bannerData} />
            <FounderComponent mediaData={media} founderData={founder} />
            <WatchVideoComponents />
            <OurCoursesComponents courseData={course} />
            <WebinarsComponents webinarsData={webinars} />
            <KidsCornerComponents />
            <LegacyComponents legacyDtat={leagcy} />
            <AnantaCommunityComponents communityData={communityData} />
            <SpreadingIdeasComponents />
        </div>
    )
}

export default HomeRoute;