import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { getData } from "../../services/routes.services";
import constants from "../../services/constants";
import { IoMdClose } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactPlayer from 'react-player/youtube';
function SpreadingIdeasComponents (){
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [ideas, setIdeas] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const PlayVideoHandler = (item) => {
        setIsPlaying(item);
    };
    const CloseVideoHandler = () => {
        setIsPlaying(null);
    };
    const fatchData = async ()=>{
        try{
            const response = await getData('idea');
            const data = response.data.Ideas;
            setIdeas(data)
        }catch(error){
            console.log("Fetch Error", error)
        }
    }
    useEffect(()=>{
        fatchData();
    }, []);
    //console.log(ideas)
    //Image Size Loader
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return(
        <div className={Styles.ideasSecBlock}>
            <div className={Styles.ideasSec}></div>
            <Container>
                <Row>
                    <Col>
                        <h3 data-aos="fade-up">Spreading <span>Ideas <br/>and Inspiring Action</span></h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={Styles.ideasContainer} data-aos="fade-up" data-aos-delay="100">
                            {ideas &&
                                ideas.map((item, index)=>{
                                    return(
                                        <div className={Styles.ideasElem} key={index}>
                                            <figure>
                                                <img src={constants.Image_BASE_URL+""+item.images} alt={item.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                            </figure>
                                            <button onClick={()=>{PlayVideoHandler(item._id)}} type="button" className={Styles.watchBtn}>Lets Watch  <img src={require('../../assest/images/video_icon.webp')} /></button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            {ideas &&
                ideas.map((item, index)=>{
                    return(
                        <>
                        {isPlaying === item._id && (
                            <div className={Styles.communityVideo} key={index}>
                                <div className={Styles.videoCont}>
                                    <button className={Styles.closeVideo} onClick={CloseVideoHandler}>
                                        <IoMdClose />
                                    </button>
                                    {item.link ? (
                                        <ReactPlayer
                                            url={item.link}
                                            playing={true}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                        />
                                    ) : item.video ? (
                                        <video width="100%" height="100%" controls autoPlay>
                                        <source src={constants.Image_BASE_URL+""+item.video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    ) : (
                                        <p>No video available</p>
                                    )}
                                </div>
                            </div>
                        )}
                        </>
                    )
                })
            }
            
        </div>
    )
}

export default SpreadingIdeasComponents;