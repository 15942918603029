import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import constants from "../../services/constants";
import { useParams, useLocation, Link } from "react-router-dom";
import parse from 'html-react-parser';
import { getData } from "../../services/routes.services";
import Loader from "../Common/PageLoader/Loader";

function CoursesDetailPage (){
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const location = useLocation();
    const { title, description, curriculum, duration, images } = location.state || {};
    const [courseData, setCourseData] = useState([]);
    const currentPageUrl = location.pathname;
    const updatedUrl = currentPageUrl.replace("/course-detail/", "");
    const [loading, setLoading] = useState(true);

   //Image Size Loader
    useEffect(()=>{
        getData(`course/${updatedUrl}`).then((res)=>{
            setCourseData(res.data.courses[0]);
            setLoading(false);
        }).catch((error)=>{
            console.log(error);
            setLoading(false);
        })
    }, []);
    if(loading){
        return <Loader />
    }
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;   
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    return(
        <div className={Styles.coursesPage + " " + Styles.topSpace}>
            <CommonBanner bannerImg={require('../../assest/images/courseBanner.jpg')} bannerTitle="Video" />
            <div className={Styles.brownBg}>
                <section className={Styles.BreadcrumbStyle}>
                    <Container>
                        <Row>
                            <Col>
                                <ul>
                                    <li><Link to='/' title="Home">Home</Link></li>
                                    <li><Link to='/courses' title="Our Courses">Our Courses</Link></li>
                                    <li>{courseData.title}</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <div className={Styles.coursesDetailBlock}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.courseDetail}>
                                <h3 className={Styles.centerTitle}>Our <span>Courses</span></h3>
                                <div className={Styles.detailContainer}>
                                    <div className={Styles.imgSide}>
                                        <figure>
                                            <img src={constants.File_BASE_URL+""+courseData.images} alt="" onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                        </figure>
                                    </div>
                                    <div className={Styles.content}>
                                        <h4>{courseData.title}</h4>
                                        {courseData.description === courseData.title ?
                                        <>
                                            <p>{parse(`${courseData.description}`)}</p>
                                            <ul>
                                                <li>
                                                    <h5>Duration <span>{parse(`${courseData.duration}`)}</span></h5>
                                                </li>
                                                <li>
                                                    <h5>Duration <span>{parse(`${courseData.curriculum}`)}</span></h5>
                                                </li>
                                            </ul>
                                        </>
                                            : "Coming Soon..."

                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CoursesDetailPage;
