import React, { useState } from "react";
import styles from "./Tab.module.css"; // Import styles from CSS module
import Banner from "./Banner";
import Founder from "./Founder";
import SocialMedia from "./SocialMedia";
import Webinar from "./Webinar";
import LegacyPage from "./LegacyPage";
import Idea from "./Idea";
import WebinarRegisterUser from "./WebinarRegisterUser";
import { useParams } from "react-router-dom";

// The HomeTab component

function HomeTab() {
  const [activeTab, setActiveTab] = useState("Tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className={styles.tabButtons}>
        {/* Buttons to switch between tabs */}
        <button
          className={
            activeTab === "Tab1"
              ? `${styles.active} ${styles.tabButton}`
              : styles.tabButton
          }
          onClick={() => handleTabClick("Tab1")}
        >
          Banner
        </button>
        <button
          className={
            activeTab === "Tab2"
              ? `${styles.active} ${styles.tabButton}`
              : styles.tabButton
          }
          onClick={() => handleTabClick("Tab2")}
        >
          Founder
        </button>
        <button
          className={
            activeTab === "Tab3"
              ? `${styles.active} ${styles.tabButton}`
              : styles.tabButton
          }
          onClick={() => handleTabClick("Tab3")}
        >
          Social Media
        </button>
        <button
          className={
            activeTab === "Tab4"
              ? `${styles.active} ${styles.tabButton}`
              : styles.tabButton
          }
          onClick={() => handleTabClick("Tab4")}
        >
          Webinar
        </button>
        <button
          className={
            activeTab === "Tab5"
              ? `${styles.active} ${styles.tabButton}`
              : styles.tabButton
          }
          onClick={() => handleTabClick("Tab5")}
        >
          Legacy
        </button>
        <button
          className={
            activeTab === "Tab6"
              ? `${styles.active} ${styles.tabButton}`
              : styles.tabButton
          }
          onClick={() => handleTabClick("Tab6")}
        >
          Ideas and Inspiring
        </button>
      </div>

      <div className={styles.tabContent}>
        {/* Conditional rendering based on the active tab */}
        {activeTab === "Tab1" && <Banner />}
        {activeTab === "Tab2" && <Founder />}
        {activeTab === "Tab3" && <SocialMedia />}
        {activeTab === "Tab4" && <Webinar />}
        {activeTab === "Tab5" && <LegacyPage />}
        {activeTab === "Tab6" && <Idea />}
        {activeTab === "Tab7" && <WebinarRegisterUser />}
      </div>
    </div>
  );
}

export default HomeTab;
