import React, { useState, useEffect, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Modal, Button, Form, Container, Table } from "react-bootstrap"; // Import Bootstrap components
import ReactPaginate from "react-paginate"; // Import pagination component
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import constants from "../../services/constants";
import {
  getData,
  createData,
  UpdateData,
  deleteData,
} from "./../../services/routes.services";
import { AuthContext } from "../../context/AuthContext";
import parse from "html-react-parser";

const Team = () => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [position, setPosition] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // Track index for editing
  const [images, setImages] = useState(null); // Initialize with null for image file
  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [showViewModal, setShowViewModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [teamToView, setteamToView] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const rowsPerPage = 10; // Items per page
  const { user } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Assuming token is stored in `user.token`
    },
  };

  useEffect(() => {
    fetchRows();
  }, []);

  const fetchRows = async () => {
    try {
      const response = await getData("teams"); // Adjust the URL to your API endpoint
      setRows(response.data); // Assuming response.data contains the rows
    } catch (error) {
      console.error("Error fetching rows:", error);
    }
  };
  const validateForm = () => {
    const errors = {};

    if (title.trim() === "") errors.title = "Title is required";
    if (position.trim() === "")
      errors.position = "Short Content is required";
    if (description.trim() === "") errors.description = "description is required";
    if (images === "") errors.image = "Image is required";

    if (formSubmitted) {
      setErrors(errors);
    }

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };
  const handleAddRow = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!validateForm()) return; // If validation fails, stop form submission
    if (title && description) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("order", order);
      formData.append("name", name);
      formData.append("description", description);
      formData.append("position", position);
      // Check if there is an image file to upload
      if (images) {
        formData.append("images", images); // Append the file
      }

      try {
        if (editIndex !== null) {
          // Update existing row
          await UpdateData(`teams/${editIndex._id}`, formData, config); // Adjust for your PUT endpoint

          const updatedRows = [...rows];
          updatedRows[editIndex] = { ...updatedRows[editIndex], ...formData }; // Update the local state
          // setRows(response.data.courses);
          fetchRows();
          toast.success("team updated successfully!");
        } else {
          // Add new row
          const response = await createData("teams", formData, config);
          const newRow = response.data; // Axios returns data in response.data
          setRows([...rows, newRow]);
          fetchRows();
          toast.success("team added successfully!");
        }
        resetForm();
      } catch (error) {
        console.error("Error saving row:", error);
        toast.error("Error saving team!");
      }
    }
  };

  const resetForm = () => {
    setTitle("");
    setName("");
    setOrder('');
    setDescription("");
    setPosition("");
    setImages(null); // Reset the images state
    setImagePreview(null);
    setEditIndex(null);
    setIsFormOpen(false);
  };

  const handleEditRow = (index) => {
    const row = index;
    setTitle(row.title);
    setName(row.name);
    setOrder(row.order);
    setDescription(row.description);
    setPosition(row.position);
    setImages(row.imageUrl); // If you have a URL for images
    setEditIndex(index);
    setIsFormOpen(true);
  };

  const handleClose = () => {
    resetForm();
    setErrors({});
  };

  const handleDeleteRow = async (index) => {
    const rowToDelete = index;
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${rowToDelete.title}"?`
    );
    if (confirmDelete) {
      try {
        const newRows = await deleteData(`teams/${rowToDelete._id}`, config);
        fetchRows();
        toast.success("team deleted successfully!"); // Show success toaster
      } catch (error) {
        toast.error("Error deleting row!"); // Show error toaster
        console.error("Error deleting row:", error);
      }
    }
  };
  function truncateText(text, maxLength) {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImages(file); // Store the image file
      setImagePreview(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    return () => {
      // Revoke preview URL when component unmounts
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  useEffect(() => {
    setErrors({});
    return () => {
      // Revoke preview URL when component unmounts
      validateForm();
    };
  }, [title, description, position, images]);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Update the current page when the user clicks
  };
  // Toggle team status (publish/unpublish)
  const toggleteamStatus = async (teamId) => {
    try {
    
      await UpdateData(`teams/${teamId}/status`, {}, config);
      fetchRows(); // Refresh teams after status change
      toast.success("team status updated successfully!"); // Show success toaster
    } catch (error) {
      console.error("Error toggling team status:", error);
    }
  };
  const handlePreviewClose = () => {
    setShowViewModal(false);
  };

  const handleViewteam = (team) => {
    setteamToView(team);
    setShowViewModal(true);
  };
  // Pagination logic
  const offset = currentPage * rowsPerPage;
  const currentPageData = rows.slice(offset, offset + rowsPerPage);
  const pageCount = Math.ceil(rows.length / rowsPerPage);

  return (
    <Container className="mt-4">
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setIsFormOpen(true)}
      >
        Add team
      </Button>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />

      <Modal show={isFormOpen} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {editIndex !== null ? "Edit team" : "Add New team"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddRow}>
          <Form.Group className="mb-3">
              <Form.Label>Team Member Name:</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Member Order:</Form.Label>
              <Form.Control
                type="number"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
              />
              {errors.title && <p style={{ color: "red" }}>{errors.title}</p>}
            </Form.Group>
            <Form.Group className="mb-3">

              <Form.Label>Member Title:</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {errors.title && <p style={{ color: "red" }}>{errors.title}</p>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Position:</Form.Label>
              <ReactQuill
                theme="snow"
                value={position}
                onChange={setPosition}
              />
              {errors.position && (
                <p style={{ color: "red" }}>{errors.position}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>team description:</Form.Label>
              <ReactQuill theme="snow" value={description} onChange={setDescription} />
              {errors.description && (
                <p style={{ color: "red" }}>{errors.description}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Upload team Image:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
              {imagePreview && (
                <div>
                  <img
                    src={imagePreview}
                    alt={"Image Preview"}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              )}
              {!imagePreview && images && (
                <img
                  src={`${constants.File_BASE_URL}uploads/${images}`}
                  alt={`Preview`}
                  style={{ width: "20%", marginTop: "10px" }}
                />
              )}
              {errors.image && <p style={{ color: "red" }}>{errors.image}</p>}
            </Form.Group>
            <Button type="submit" variant="success">
              {editIndex !== null ? "Update" : "Submit"}
            </Button>
            <Button variant="secondary" onClick={handleClose} className="ms-2">
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th style={{ width: "3%" }}>#</th>
            <th style={{ width: "17%" }}>Member Name</th>
            <th style={{ width: "25%" }}>Description</th>
            <th style={{ width: "30%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((row, index) => (
            <tr key={row._id}>
              <td>{offset + index + 1}</td>
              <td>{row.name}</td> {/* Fixed to use row.title */}
             
              <td dangerouslySetInnerHTML={{ __html: truncateText(row.description,100) }}></td>
            
              <td>
                <Button variant="warning" onClick={() => handleEditRow(row)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteRow(row)}
                  className="ms-2"
                >
                  Delete
                </Button>
                {"     "}
  
                <Button variant="primary" onClick={() => handleViewteam(row)}>
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showViewModal} onHide={handlePreviewClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Meamber Name : {teamToView && teamToView.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {teamToView !== null ? (
            <Table striped bordered hover>
              <thead>
                {/* <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr> */}
              </thead>
              <tbody>
              <tr>
                  <td>Profile Picture</td>
                  <td>
                    <img
                      src={`${constants.File_BASE_URL}uploads/${teamToView.imageUrl}`}
                      alt={`Preview`}
                      style={{ width: "50%", marginTop: "10px" }}
                    />
                  </td>
                </tr>
              <tr>
                  <td>Title</td>
                  <td>{parse(`${teamToView.title}`)}</td>
                </tr>
                <tr>
                  <td>position</td>
                  <td>{parse(`${teamToView.position}`)}</td>
                </tr>
                <tr>
                  <td>description</td>
                  <td>{parse(`${teamToView.description}`)}</td>
                </tr>
    
               
              </tbody>
            </Table>
          ) : (
            <p>No team details available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePreviewClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination Component */}
      {rows.length > rowsPerPage && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      )}
    </Container>
  );
};

export default Team;
