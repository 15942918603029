import React, { useState, useEffect } from "react";
import Styles from "../Styles/Common.module.css";
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { getData } from "../../services/routes.services";
import parse from "html-react-parser";
import constants from "../../services/constants";
import Loader from "../Common/PageLoader/Loader";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);
};

function BlogsComponent() {
  const location = useLocation();
  const { menu } = useSelector(state => state.header);
  const currentPageUrl = location.pathname;
  const currentMenuItem = MenuPath(menu, currentPageUrl);
  const BannerImage = currentMenuItem ? currentMenuItem.images : ''; 
  const BannerTitle = currentMenuItem ? currentMenuItem.title : ''; 
  const [imageDimensions, setImageDimensions] = useState({width: 0,height: 0,});
  const [blogData, setBlogData] = useState([]);
  const TextLimit = 130;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; 
  const [loading, setLoading] = useState(true);
  //Image Size Loader
  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
  };
  const fetchRows = async () => {
    try {
      const response = await getData("blogs");
      const data = response.data;
      setBlogData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching rows:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRows();
  }, []);
  if(loading){
      return <Loader />
  }
 
  const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = Array.isArray(blogData)
  ? blogData.slice(indexOfFirstItem, indexOfLastItem)
  : [];
const totalPages = Math.ceil(blogData.length / itemsPerPage);
const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
  window.scrollTo(0, 0);
};

  return (
    <div className={Styles.blogPage + " " + Styles.topSpace}>
      <CommonBanner bannerImg={constants.Image_BASE_URL+""+BannerImage} bannerTitle={BannerTitle}/>
      <div className={Styles.brownBg}>
        <BreadcrumbComponent home="Home" listOne="Blogs" />
      </div>
      <div className={Styles.blogsBlock}>
        <Container>
          <Row>
            <Col>
              <h3 className={Styles.centerTitle}>
                <span>Blogs</span>
              </h3>
            </Col>
          </Row>
          <Row>
            {currentItems  &&
              currentItems .map((item, index) => {
                return (
                  <Col md={4} key={index} className={Styles.bolgWidth}>
                    <div className={Styles.blogList}>
                      <figure>
                        <img src={constants.Image_BASE_URL + "" + item.imageUrl} alt={item.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                      </figure>
                      <div className={Styles.content}>
                        <h6>{item.title}</h6>
                        {parse(`${item.shortContent.length > TextLimit ? item.shortContent.substring(0, TextLimit) + "..." : item.shortContent}`)}
                        <div className={Styles.blogTime}>
                          <span>{formatDate(item.createdAt)}</span>
                          <Link to={{ pathname: `/blogDetail/${item.link}`, }}  title={item.title}><IoIosArrowRoundForward /></Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
        <div className={Styles.courseBtn}>
          <div className={Styles.pagination}>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={currentPage === index + 1 ? Styles.active : Styles.pageActive}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogsComponent;
