import React, { useState, useEffect } from "react";
import axios from "axios";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHENNAL_ID;

function YoutubeSubscribres() {
    const [subscriberCount, setSubscriberCount] = useState(null);

    useEffect(() => {
        const fetchSubscriberCount = async () => {
            try {
                const response = await axios.get(
                    `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${CHANNEL_ID}&key=${API_KEY}`
                );
                const count = response.data.items[0].statistics.subscriberCount;
                setSubscriberCount(count);
            } catch (error) {
                console.error("Error fetching subscriber count:", error);
            }
        };

        fetchSubscriberCount();
    }, []);

    return (
        <div>
           
            {subscriberCount !== null ? (
                <span>{subscriberCount} Subscribers</span>
            ) : (
                <span>Loading...</span>
            )}
        </div>
    );
}

export default YoutubeSubscribres;
