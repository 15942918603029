import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import constants from "../../services/constants";
import { getOurCourse } from "../../services/routes.services";
import Loader from "../Common/PageLoader/Loader";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
function CoursesPage (){
    const location = useLocation();
  const { menu } = useSelector(state => state.header);
  const currentPageUrl = location.pathname;
  const currentMenuItem = MenuPath(menu, currentPageUrl);
  const BannerImage = currentMenuItem ? currentMenuItem.images : ''; 
  const BannerTitle = currentMenuItem ? currentMenuItem.title : ''; 
    const [courseData, setCourseData] = useState([]);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [loading, setLoading] = useState(true);
    //Image Size Loader
    useEffect(()=>{
        getOurCourse().then((res)=>{
            setCourseData(res.data.courses)
            setLoading(false);
        }).catch((error)=>{
            console.log(error);
            setLoading(false);
        })
    }, []);
    //console.log(courseData)
    if(loading){
        return <Loader />
    }
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    return(
        <div className={Styles.coursesPage + " " + Styles.topSpace}>
            <CommonBanner bannerImg={constants.Image_BASE_URL+""+BannerImage} bannerTitle={BannerTitle}/>
            <div className={Styles.brownBg}>
                <BreadcrumbComponent home="Home" listOne="Our Courses" />
            </div>
            <div className={Styles.coursesBlock + " " + Styles.textureBg}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.multiCourse}>
                                <Row>
                                    <Col md={4}>
                                        <div className={Styles.heading}>
                                            <h3>Our <span>Courses</span></h3>
                                            <p>At Ananta, we believe parenting should be a beautiful journey, not a struggle. Our heartfelt mission is to stand by parents, offering them the guidance, support, and encouragement they need to navigate through the ups and downs of raising children. </p>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <div className={Styles.allCourseCont}>
                                            {courseData &&
                                                courseData.map((item, index)=>{
                                                    return(
                                                        <div className={Styles.courseItem} key={index}>
                                                            <figure>
                                                                <img src={constants.File_BASE_URL+""+item.images} alt={item.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                                            </figure>
                                                            <div className={Styles.courseName}>
                                                                <h5>{item.title}</h5>
                                                                <Link to={{
                                                                    pathname : `/course-detail/${item.link}`,
                                                                }}
                                                                //state={{title: item.title, description: item.description, curriculum: item.curriculum, duration: item.duration, images: item.images }}
                                                                title={item.link}><img src={require('../../assest/images/Link_arrow.webp')} alt="link arrow" /></Link>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CoursesPage;
