import React, { useState, useEffect } from "react";
import Styles from "../Styles/Common.module.css";
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import constants from "../../services/constants";
import { getWebinars } from "../../services/routes.services";
import Loader from "../Common/PageLoader/Loader";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
function CommingWebinars() {
  const location = useLocation();
  const { menu } = useSelector(state => state.header);
  const currentPageUrl = location.pathname;
  const currentMenuItem = MenuPath(menu, currentPageUrl);
  const BannerImage = currentMenuItem ? currentMenuItem.images : ''; 
  const BannerTitle = currentMenuItem ? currentMenuItem.title : ''; 
  const [imageDimensions, setImageDimensions] = useState({width: 0,height: 0,});
  const [webinarsData, setWebinarsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getWebinars()
      .then((res) => {
        setWebinarsData(res.data.webinars);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed", error);
        setLoading(false);
      });
  }, []);
  if(loading){
      return <Loader />
  }
  //Image Size Loader
  //console.log(webinarsData);
  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
  };
  return (
    <div className={Styles.webinarsPage + " " + Styles.topSpace}>
      <CommonBanner bannerImg={constants.Image_BASE_URL+""+BannerImage} bannerTitle={BannerTitle}/>
      <BreadcrumbComponent home="Home" listOne="Webinars" />
      <div className={Styles.commingBlock}>
        <Container>
          <Row>
            <Col>
              <div className={Styles.commingContainer}>
                <h3 className={Styles.centerTitle}>
                  Upcomming <span>Webinars</span>
                </h3>
                {webinarsData && webinarsData.length > 0 && (
                  <div className={Styles.commingElem}>
                    <figure>
                      <img src={constants.Image_BASE_URL + "" + webinarsData[0].images} alt={webinarsData[0].title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                    </figure>
                    <div className={Styles.content}>
                      <h6>{webinarsData[0].title}</h6>
                      <Link to={{ pathname: `/webinars-detail/${webinarsData[0].link}`, }} title={webinarsData[0].link}>
                        <img alt="Link Arrow" src={require("../../assest/images/Link_arrow.webp")} />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={Styles.recentBlock}>
        <Container>
          <Row>
            <Col>
              <div className={Styles.recentContainer}>
                <h3 className={Styles.centerTitle}>
                  Recent <span>Webinars</span>
                </h3>
                <div className={Styles.recentBox}>
                  {webinarsData &&
                    webinarsData.slice(1).map((item, index) => {
                      return (
                        <>
                        <div className={Styles.recentElem} key={index}>
                          <figure>
                            <img src={constants.Image_BASE_URL + "" + item.images} alt={item.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                          </figure>
                          <div className={Styles.content}>
                            <h6>{item.title}</h6>
                            <Link to={{ pathname: `/webinars-detail/${item.link}`, }} title={item.link}>
                              <img alt="Link Arrow" src={require("../../assest/images/Link_arrow.webp")}/>
                            </Link>
                          </div>
                        </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CommingWebinars;
