import React, { useState, useEffect } from "react";
import Styles from "../../Components/Styles/Common.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowRoundForward } from "react-icons/io";
import constants from "../../services/constants";
import Founder from "../../assest/images/founder.png";

const Dashboard = () => {
  return (
    <div className={Styles.aboutPage}>
    
        <Container>
            <Row>
               
                <Col md={12}>
                    <div className={Styles.valueContainer}>
                        <Row>
                
                            <Col md={12}>
                                <div className={Styles.valueElem}>
                                    <figure style={{textAlign:"center"}}>
                                        <img src={Founder} alt="Founder" style={{width:"40%"}} />
                                    </figure>
                                   
                                </div>
                            </Col>
                            
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    
   
  );
  };
  
  export default Dashboard;
  