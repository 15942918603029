import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const AdminProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  //console.log("object====", user);
  //return false;

  // Check if user is logged in and has the 'admin' role
  if (user && user.token && user.role === "admin") {
    return children;
  } else if (
    localStorage.getItem("token") &&
    localStorage.getItem("role") === "admin"
  ) {
    return children;
  }

  // Redirect non-admin users to the login page
  return <Navigate to="/login" />;
};

export default AdminProtectedRoute;
