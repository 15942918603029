import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Dashboard = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <div>
      <h2>User Dashboard</h2>
      {user && (
        <a href="#" onClick={logout}>
          Logout
        </a>
      )}
    </div>
  );
};

export default Dashboard;
