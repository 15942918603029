import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { getData } from "../../services/routes.services";
import constants from "../../services/constants";
import parse from 'html-react-parser';
import { useLocation  } from "react-router-dom";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
import Loader from "../Common/PageLoader/Loader";
import OurTemePage from "../OurTeam/Team";
function AboutUsComponent (){
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    //console.log(currentMenuItem)
    const BannerImage = currentMenuItem ? currentMenuItem.images : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.title : ''; 
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [aboutData, setAboutData] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async ()=>{
        try{
            const response = await getData('aboutus');
            const data = response.data;
            setAboutData(data.about);
            setLoading(false);
        }catch(error){
            console.log("Fetch Error", error);
            setLoading(false);
        }
    }
    useEffect(()=>{
        fetchData();
    }, [])
    if(loading){
        return <Loader />
    }
    //Image Size Loader
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    return(
        <div className={Styles.aboutPage +" " + Styles.topSpace}>
            <CommonBanner bannerImg={constants.Image_BASE_URL+""+BannerImage} bannerTitle={BannerTitle}/>
            <BreadcrumbComponent home="Home" listOne="About Us" />
            <div className={Styles.aboutBlock} id="founder">
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.aboutBlockSpace}>
                                <Row>
                                    {aboutData && aboutData.length > 0 &&
                                        <>
                                            <Col md={5} className={Styles.alignBottom}>
                                                <figure>
                                                    <img src={constants.Image_BASE_URL+""+aboutData[0].images} alt={aboutData[0].name} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                                </figure>
                                            </Col>
                                            <Col md={7}>
                                                <div className={Styles.content}>
                                                    <h2>{aboutData[0].name}</h2>
                                                    {parse(`${aboutData[0].description}`)}
                                                </div>
                                            </Col>   
                                        </>   
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.valueBlock}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3 className={Styles.centerTitle}>Core <span>Values</span></h3>
                        </Col>
                        <Col md={12}>
                            <div className={Styles.valueContainer}>
                                <Row>
                                    {aboutData &&
                                        aboutData.slice(1, 4).map((item, index)=>{
                                            return(
                                                <Col md={4} key={index}>
                                                    <div className={Styles.valueElem}>
                                                        <figure>
                                                            <img src={constants.Image_BASE_URL+""+item.images} alt={item.name} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                                        </figure>
                                                        <h5>{item.name}</h5>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <OurTemePage />
            <div className={Styles.purposeBlock}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3 className={Styles.centerTitle}>Core <span>Purpose</span></h3>
                        </Col>
                        <Col md={12}>
                            {aboutData && aboutData.length > 0 &&
                                <div className={Styles.purposeContainer}>
                                    <div className={Styles.purposeImg}>
                                        <figure>
                                            <img src={constants.Image_BASE_URL+""+aboutData[4].images} alt={aboutData[4].name} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                        </figure>
                                    </div>
                                    <div className={Styles.purposeContent}>
                                        {parse(`${aboutData[4].description}`)}
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AboutUsComponent;