import React, { useState, useEffect  } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';
import constants from "../../services/constants";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";

function BannerComponents(props){
    const BannerContent = props.bannerData[0];
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 0 },
          items: 1
        }
      };
    //Image Size Loader
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    const extractWords = (text) => {
        const words = text.split(' ');
        if (words.length < 2) return { first: words[0], second: '' };
        return { first: words[0], second: words[1], rest: words.slice(2).join(' ') };
    };
    const { first: titleFirst, second: titleSecond, rest: titleRest } = extractWords(BannerContent.title);
    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return(
        <div className={Styles.homeBanner}>
            <div className={Styles.bannerContainer}>
                <Container>
                    <Row>
                        <Col>
                            <div className={Styles.bannerContent}>
                            <h1 data-aos="fade-up">
                                    {titleFirst}{' '}
                                    {titleSecond && <span className={Styles.secondWord}>{titleSecond}</span>} 
                                    {titleRest}
                                </h1>
                                <div data-aos="fade-up" data-aos-delay="500">{parse(`${BannerContent.description}`)}</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.bannerImg}>
                <figure>
                    <img src={constants.Image_BASE_URL+""+BannerContent.images0} alt={BannerContent.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height}/>
                </figure>
                <figure>
                    <img src={constants.Image_BASE_URL+""+BannerContent.images1} alt={BannerContent.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height}/>
                </figure>
                <figure>
                    <img src={constants.Image_BASE_URL+""+BannerContent.images2}  alt={BannerContent.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height}/>
                </figure>
            </div>
            <div className={Styles.BannerSlider}>
                <Carousel responsive={responsive} transitionDuration={500} autoPlay={true} infinite={true} arrows={false}>
                    <figure>
                        <img src={constants.Image_BASE_URL+""+BannerContent.images0} alt={BannerContent.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height}/>
                    </figure>
                    <figure>
                        <img src={constants.Image_BASE_URL+""+BannerContent.images1} alt={BannerContent.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height}/>
                    </figure>
                    <figure>
                        <img src={constants.Image_BASE_URL+""+BannerContent.images2}  alt={BannerContent.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height}/>
                    </figure>
                </Carousel>
            </div>
        </div>
    )
}

export default BannerComponents;