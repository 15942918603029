import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import AdminLayout from "./Admin/AdminLayout/AdminLayout";
import DashboardAdmin from "./Admin/Pages/Dashboard";
import Home from "./Admin/Pages/HomePages/HomeTab";
import Blogs from "./Admin/Pages/Blog";
import Teams from "./Admin/Pages/Teams";
import Media from "./Admin/Pages/Media";
import HeaderBackend from "./Admin/Pages/Header";
import Course from "./Admin/Pages/Course";
import AboutAdmin from "./Admin/Pages/AboutUsPage";
import Community from "./Admin/Pages/Community";
import PrivateRoute from "./store/PrivateRoutes";
//import Login from './Admin/LoginPages/Login';
import { AuthProvider } from "./context/AuthContext";

import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";
import HomeRoute from "./Components/HomePage/HomeRoute";
import AboutUsComponent from "./Components/AboutPage/AboutUs";
import VideosComponent from "./Components/VideosPage/Videos";
import BlogsComponent from "./Components/BlogsPage/Blogs";
import BlogDetailComponent from "./Components/BlogsPage/BlogDetail";
import Register from "./Components/AuthPage/Register";
import Login from "./Components/AuthPage/Login";
import ProtectedRoute from "./store/ProtectedRoute";
import AdminProtectedRoute from "./store/AdminProtectedRoute";
import Dashboard from "./Components/AuthPage/Dashboard";
import CoursesPage from "./Components/OurCourses/Courses";
import CoursesDetailPage from "./Components/OurCourses/CoursesDetail";
import CommunityComponent from "./Components/CommunityPage/Community";
import CommingWebinars from "./Components/WebinarsPage/CommingWebinars";
import WebinarsDetail from "./Components/WebinarsPage/WebinarsDetail";
import ForgotPassword from "./Components/AuthPage/ForgotPassword";
import BookingSessionComponent from "./Components/BookSession/Booking";
import BookSessionAdmin from "./Admin/Pages/BookSession";
import MediaComponent from "./Components/Media/Media";
import UpcomingWebinarForm from "./Components/BookSession/UpcomingWebinarForm";
import WebinarRegisterUser from "./Admin/Pages/HomePages/WebinarRegisterUser";
import OurTemePage from "./Components/OurTeam/Team";
// import YouRoute from './you.js'

function App() {
  const { pathname } = useLocation();

  // Ensure the window scrolls to the top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const HeaderFooter = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  };

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<HeaderFooter />}>
          <Route path="/" element={<HomeRoute />}></Route>
          {/* <Route path='/you' element={<YouRoute />}></Route> */}
          <Route path="/about" element={<AboutUsComponent />}></Route>
          <Route path="/video" element={<VideosComponent />}></Route>
          <Route path="/blogs" element={<BlogsComponent />}></Route>
          <Route path="/blogDetail/:blogUrl" element={<BlogDetailComponent />}></Route>
          <Route path="/courses" element={<CoursesPage />}></Route>
          <Route path="/course-detail/:courseId" element={<CoursesDetailPage />}></Route>
          <Route path="/community" element={<CommunityComponent />} />
          <Route path="/webinars" element={<CommingWebinars />} />
          <Route path="/webinars-detail/:webinarsId" element={<WebinarsDetail />}/>
          <Route path="/course-detail/:courseId" element={<CoursesDetailPage />}></Route>
          <Route path="/community" element={<CommunityComponent />} />
          <Route path="/webinars" element={<CommingWebinars />} />
          <Route path="/session-book" element={<BookingSessionComponent />} />
          <Route path="/media" element={<MediaComponent />} />
          <Route path="/upcoming-webinar" element={<UpcomingWebinarForm />} />
          <Route path="/our-team" element={<OurTemePage />} />
        </Route>

        {/* Login Route */}
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        {/* Admin Routes: Protected by PrivateRoute */}
        <Route
          path="/admin"
          element={
            <AdminProtectedRoute>
              <AdminLayout />
            </AdminProtectedRoute>
          }
        >
          <Route path="blog" element={<Blogs />} />
          <Route path="teams" element={<Teams />} />
          <Route path="media" element={<Media />} />
          <Route path="home" element={<Home />} />
          <Route path="dashboard" element={<DashboardAdmin />} />
          <Route path="course" element={<Course />} />
          <Route path="community" element={<Community />} />
          <Route path="header" element={<HeaderBackend />} />
          <Route path="aboutus" element={<AboutAdmin />} />
          <Route path="webinaruser/:wid" element={<WebinarRegisterUser />} />
          <Route path="booksessionlist" element={<BookSessionAdmin />} />
        </Route>

        {/* Fallback Route: Catch-all for undefined routes (404) */}
        <Route path="*" element={<h2>404 - Page Not Found</h2>} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
