import React from "react";
import Styles from '../../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const BreadcrumbComponent=(props)=>{
    return(
        <section className={Styles.BreadcrumbStyle}>
            <Container>
                <Row>
                    <Col>
                        <ul>
                            <li><Link to='/' title={props.home}>{props.home}</Link></li>
                            <li>{props.listOne}</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BreadcrumbComponent;