import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Modal, Button, Form, Container, Table } from "react-bootstrap"; // Import Bootstrap components
import ReactPaginate from "react-paginate"; // Import pagination component
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import constants from "../../../services/constants";
import {
  getData,
  createData,
  UpdateData,
  deleteData,
} from "../../../services/routes.services";
import { AuthContext } from "../../../context/AuthContext";

import axios from "axios";

const CHUNK_SIZE = 2 * 1024 * 1024; // 10 MB per chunk

const Idea = () => {
  const [rows, setRows] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [newLink, setNewLink] = useState("");
  const [isDataOpen, setIsDataOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // Track index for editing
  const [images, setImages] = useState([""]); // Initialize with empty image strings
  const [video, setVideo] = useState(""); // State to store video data
  const [videoPath, setVideoPath] = useState("");
  const [totalChunks, setTotalChunks] = useState(0);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [videoPreview, setVideoPreview] = useState(null);
  const [imagePreview, setImagePreview] = useState("null");

  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const rowsPerPage = 10; // Items per page
  const { user } = useContext(AuthContext);

  const uniqueFilename = file ? `${Date.now()}-${file.name}` : "";
  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Assuming token is stored in `user.token`
    },
  };
  useEffect(() => {
    fetchRows();
  }, []);

  const fetchRows = async () => {
    try {
      const response = await getData("idea"); // Adjust the URL to your API endpoint

      const data = response.data.Ideas;
      console.log(data);
      setRows(data);
    } catch (error) {
      console.error("Error fetching rows:", error);
    }
  };

  const handleAddRow = async (e) => {
    e.preventDefault();
    // if (!file) {
    //   //alert("Please select a file");
    //   toast.error("Please select a file");
    //   return;
    // }
    let videoPath='';
    if (file) {
    setUploading(true);

    // First, upload the video in chunks
    //await uploadChunks(file, uniqueFilename);
     videoPath = await uploadChunks(file, uniqueFilename);
    }
    //return false;
    if (newItem ) {
      const formData = new FormData();
      formData.append("title", newItem);
     formData.append("video", videoPath);
formData.append("link",newLink);
      // Check if there is an image file to upload

      if (images) {
        formData.append(`images`, images); // Append each file object directly
      }
     
      try {
        if (editIndex !== null) {
          await UpdateData(`idea/${editIndex._id}`, formData,config); // Adjust for your PUT endpoint
          const updatedRows = [...rows];
          updatedRows[editIndex] = { ...updatedRows[editIndex], ...formData }; // Update the local state
          setRows(updatedRows);

          toast.success("Row updated successfully!");
          fetchRows();
        } else {
          const response = await createData("idea", formData, config);

          toast.success("Row Added successfully!");
          fetchRows();
        }
        resetForm();
      } catch (error) {
        console.error("Error saving row:", error);
        toast.error("Error saving row!");
      }
    }
  };

  const resetForm = () => {
    setNewItem("");
    setNewLink('');
    setImages([""]);
    setVideo(""); // Reset video state
    setEditIndex(null);
    setVideoPath("");
    setVideoPreview("");//Reset Video privew
    setIsDataOpen(false);
  };

  const handleEditRow = (index) => {
    const row = index;
    setNewItem(row.title);
    setNewLink(row.link);
    setImages(row.images);
    setVideo(row.video); // Load video into state
    setEditIndex(index);
    setIsDataOpen(true);
  };

  const handleClose = () => {
    resetForm();
  };

  const handleDeleteRow = async (index) => {
    const rowToDelete = index;
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${rowToDelete.title}"?`
    );
    if (confirmDelete) {
      try {
        await deleteData(`idea/${rowToDelete._id}`,config);

        fetchRows();
        toast.success("Row deleted successfully!"); // Show success toaster
      } catch (error) {
        toast.error("Error deleting row!"); // Show error toaster
        console.error("Error deleting row:", error);
      }
    }
  };

  const handleImageChange = (index, e) => {
    if (e && e.target && e.target.files[0]) {
      // Check for the existence of e and e.target
      const file = e.target.files[0];
      setImages(file); // Store the image file
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleVideoChange = (e) => {
    setVideo(e.target.files[0]);
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setVideoPreview(URL.createObjectURL(e.target.files[0])); // Generate preview URL
    // Calculate total chunks
    const chunkSize = 10 * 1024 * 1024; // 10MB per chunk
    const chunks = Math.ceil(selectedFile.size / chunkSize);
    setTotalChunks(chunks);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const uploadChunks = async (file, uniqueFilename) => {
    const chunkSize = 2 * 1024 * 1024; // 10MB per chunk
    const totalChunks = Math.ceil(file.size / chunkSize);

    // Upload each chunk
    for (let i = 0; i < totalChunks; i++) {
      const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
      const formData = new FormData();
      formData.append("chunk", chunk);
      formData.append("filename", uniqueFilename);
      formData.append("chunkNumber", i + 1);
      formData.append("totalChunks", totalChunks);

      try {
        await axios.post(
          `${constants.API_BASE_URL}idea/upload-chunk`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              const percent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percent);
            },
          }
        );
      } catch (error) {
        console.error("Error uploading chunk", error);
        throw error;
      }
    }

    // After all chunks are uploaded, set the video path

    setVideoPath(`${uniqueFilename}`); // Set the video path for saving in the idea data
    return `${uniqueFilename}`;
  };
  useEffect(() => {
    return () => {
      // Revoke preview URL when component unmounts
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);
  const offset = currentPage * rowsPerPage;
  const currentPageData = rows.slice(offset, offset + rowsPerPage);
  const pageCount = Math.ceil(rows.length / rowsPerPage);

  return (
    <Container className="mt-4">
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setIsDataOpen(true)}
      >
        Add Entry
      </Button>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />

      <Modal show={isDataOpen} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {editIndex !== null ? "Edit Entry" : "Add New Entry"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddRow}>
            <Form.Group className="mb-3">
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
                required
              />
            </Form.Group>
          
            <Form.Group className="mb-3">
              <Form.Label>Link:</Form.Label>
              <Form.Control
                type="text"
                value={newLink}
                onChange={(e) => setNewLink(e.target.value)}
                required
              />
            </Form.Group>
          

            {/* Image Upload Section */}

            <Form.Group className="mb-3">
              <Form.Label>Upload Image :</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleImageChange(0, e)}
                accept="image/*"
              />

              {images && (
                <img
                  src={`${constants.Image_BASE_URL}${images}`}
                  alt={`Preview1`}
                  style={{ width: "20%", marginTop: "10px" }}
                />
              )}

              {!images && imagePreview && (
                <img
                  src={`${imagePreview}`}
                  alt={`Preview`}
                  style={{ width: "20%", marginTop: "10px" }}
                />
              )}
            </Form.Group>

            {/* Video Upload Section */}
            <Form.Group className="mb-3">
              <Form.Label>Upload Video:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleVideoChange}
                accept="video/*"
              />
              {!videoPreview && video && (
                <video
                  src={`${constants.Image_BASE_URL}${video}`}
                  controls
                  style={{ width: "100%", marginTop: "10px" }}
                />
              )}
              {videoPreview && (
                <video
                  src={`${videoPreview}`}
                  controls
                  style={{ width: "100%", marginTop: "10px" }}
                />
              )}
            </Form.Group>

            <Button type="submit" variant="success">
              {editIndex !== null ? "Update" : "Submit"}
            </Button>
            <Button variant="secondary" onClick={handleClose} className="ms-2">
              Cancel
            </Button>
            <div>{uploading && <p>Uploading... {progress}%</p>}</div>
          </Form>
        </Modal.Body>
      </Modal>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>#</th>
            <th style={{ width: "20%" }}>Title</th>
            <th style={{ width: "45%" }}>Video</th>
            <th style={{ width: "30%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((row, index) => (
            <tr key={index}>
              <td>{offset + index + 1}</td>
              <td>{row.title}</td>
              <td >
                <video
                  src={`${constants.Image_BASE_URL}${row.video}`}
                  controls
                  style={{ width: "20%", marginTop: "00px" }}
                />
              </td>

              <td>
                <Button variant="warning" onClick={() => handleEditRow(row)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteRow(row)}
                  className="ms-2"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination Component */}
      {rows.length > rowsPerPage && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      )}
    </Container>
  );
};

export default Idea;
