import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import constants from "../../services/constants";
import AOS from "aos";
import "aos/dist/aos.css";
function WebinarsComponents (props){
    const webinarsData = props.webinarsData;
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return(
        <div className={Styles.webinarsSec}>
            <Container>
                <Row>
                    <Col>
                        <h3 data-aos="fade-up"><span>Webinars</span></h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className={Styles.webContainer} data-aos="fade-up" data-aos-delay="100">
                            <h5>Recent Webinars</h5>
                            <figure>
                                <img src={constants.Image_BASE_URL+""+webinarsData[1].images} alt={webinarsData[1].title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                            </figure>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={Styles.webContainer} data-aos="fade-up" data-aos-delay="150">
                            <h5>Up Coming Webinars</h5>
                            <figure>
                                <img src={constants.Image_BASE_URL+""+webinarsData[0].images} alt={webinarsData[0].title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                            </figure>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={Styles.courseBtn}>
                <Link to='/webinars' title="View all" className={Styles.blackBtn + " " + Styles.commonBtn}>View all <IoIosArrowRoundForward /></Link>
            </div>
        </div>
    )
}

export default WebinarsComponents;