import React, { useState } from "react";
import Styles from '../../Styles/Common.module.css';

function CommonBanner(props){
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    //Image Size Loader
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    return(
        <div className={Styles.innerBanner}>
            <img src={props.bannerImg} alt={props.bannerTitle} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height}  />
        </div>
    )
}

export default CommonBanner;