import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Style from "./Login.module.css"; // Import CSS file
import constants from "../../services/constants";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    //alert(e.target.name + "=========" + e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      login(formData.email, formData.password);
    } catch (err) {
      setError(err.response?.data?.message || "Login failed");
    }
  };

  return (
    <div className={Style.loginContainer}>
      <h4>Login</h4>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit} className={Style.loginForm}>
        <div className={Style.formGroup}>
          <label>
            Username:
            <input
              type="text"
              name="email"
              onChange={handleChange}
              required
              className={Style.formInput}
            />
          </label>
        </div>
        <div className={Style.formGroup}>
          <label>
            Password:
            <input
              type="password"
              name="password"
              onChange={handleChange}
              required
              className={Style.formInput}
            />
          </label>
        </div>
        <button type="submit" className={Style.loginButton}>
          Login
        </button>
      </form>
      <p>
        <button onClick={() => navigate("/forgot-password")}>
          Forgot Password?
        </button>
      </p>
    </div>
  );
};

export default Login;
