import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logo from '../../../assest/images/footer_logo.webp';
import Styles from '../../Styles/Footer.module.css'
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useSelector } from 'react-redux';

function Footer (){
    const { menu } = useSelector(state => state.header);
    console.log(menu)
    return(
        <footer>
            <div className={Styles.topFooter}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className={Styles.leftSide}>
                                <div className={Styles.fLogo}>
                                    <img src={Logo} alt="Ananta" />
                                </div>
                                <div className={Styles.footerLink}>
                                    <h5>About Us</h5>
                                    <ul>
                                        {menu &&
                                            menu.map((item, index)=>{
                                                return(
                                                    <li key={index}><Link to={item.link} title={item.link}>{item.title}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={Styles.rightSIde}>
                                <div className={Styles.flexFooter}>
                                    <div className={Styles.footerLink}>
                                        <h5>Quick Contactss</h5>
                                        <ul>
                                            <li>Gurgaon: <a href="tel:+91 1245632588" title="+91 1245632588">+91 1245632588</a></li>
                                            <li>Delhi: <a href="tel:+91 11 1245632588" title="+91 11 1245632588">+91 11 1245632588</a></li>
                                            <li>Write to us: <Link to='mailto:info@shreyadubey.com' title="info@shreyadubey.com">info@shreyadubey.com</Link></li>
                                        </ul>
                                    </div>
                                    <div className={Styles.footerLink + " "+ Styles.linkSpace}>
                                        <ul className={Styles.social}>
                                            <li><a href='https://www.instagram.com/dr.shreyadubey/' target="_blank"><img src={require('../../../assest/images/insta_icon.webp')} /></a></li>
                                            <li><a href="https://www.youtube.com/channel/UCqMQ4Ph5I8192RUUeU3Ewxg" target="_blank"><img src={require('../../../assest/images/youtube_icop.webp')} /></a></li>
                                        </ul>
                                        <div className={Styles.withAnanta}>
                                            <a href="tel:9821114112"><img src={require('../../../assest/images/phone.png')} /> <p>Connect with Ananta <span>9821114112</span></p> <IoIosArrowRoundForward className={Styles.fIcon} /></a>
                                            <Link to='/session-book'><img src={require('../../../assest/images/calendar.png')} /> <p>Collaborate <span>with Ananta</span></p> <IoIosArrowRoundForward className={Styles.fIcon} /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={Styles.copyRightSec}>
                <Container>
                    <Row>
                        <Col className={Styles.copyFlex}>
                            <p>© Copyright 2024. ananta. All Rights Reserved.</p>
                            <p><Link to='https://olive.in/' target="_blank">Design and development by <img src={require('../../../assest/images/olive_logo.png')} /></Link></p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}

export default Footer