import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import constants from "../../services/constants";
import AOS from "aos";
import "aos/dist/aos.css";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th');
    return `${dayWithSuffix} ${month} ${year}`;
  };

function AnantaCommunityComponents (props){
    const communityData = props.communityData;
    console.log(communityData)
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    //Image Size Loader
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    const getYouTubeID = (url) => {
        if (!url || typeof url !== "string") {
            return null;
        }
        const regExp = 
            /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return match && match[1].length === 11 ? match[1] : null;
    };
    const thumbnailImage = (item) => {
        const videoID = getYouTubeID(item.extralink);
        if (videoID) {
          return `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`;
        } else if (item.video) {
          return constants.Image_BASE_URL + item.images;
        }
        return null;
      };
      useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return(
        <div className={Styles.communityec}>
            <Container>
                <Row>
                    <Col>
                        <h3 data-aos="fade-up">The <span>Ananta Community</span></h3>
                    </Col>
                </Row>
                <Row>
                    {communityData &&
                        communityData.slice(0, 3).map((item, index)=>{
                            return(
                                <Col md={4} key={index}  data-aos="fade-up" data-aos-delay="50">
                                    <div className={Styles.communityElem}>
                                        <figure>
                                            <img src={thumbnailImage(item)} alt={item.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                        </figure>
                                        <div className={Styles.detail}>
                                            <span>{formatDate(item.date)}</span>
                                            <h5>{item.title} <Link to='/' title={item.title}><img src={require('../../assest/images/Link_arrow.webp')} /></Link></h5>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Row>
                    <Col>
                        <div className={Styles.courseBtn} data-aos="fade-up" data-aos-delay="100">
                            <Link to='/community' title="View all" className={Styles.whiteBtn + " " + Styles.commonBtn}>View all <IoIosArrowRoundForward /></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AnantaCommunityComponents;