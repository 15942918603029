import React, { useState, useEffect, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Modal, Button, Form, Container, Table } from "react-bootstrap"; // Import Bootstrap components
import ReactPaginate from "react-paginate"; // Import pagination component
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import constants from "../../../services/constants";
import { getData, deleteData } from "../../../services/routes.services";
import { AuthContext } from "../../../context/AuthContext";
import { useParams } from "react-router-dom";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};
const WebinarRegisterUser = () => {
  const { wid } = useParams();
  console.log("===========", wid);
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const rowsPerPage = 10; // Items per page
  const { user } = useContext(AuthContext);

  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Assuming token is stored in `user.token`
    },
  };

  useEffect(() => {
    fetchRows();
  }, []);

  const fetchRows = async () => {
    try {
      const response = await getData(`webinar/webinarregisteruser/${wid}`); // Adjust the URL to your API endpoint

      const data = response.data.webinars;
      setRows(data);
    } catch (error) {
      console.error("Error fetching rows:", error);
    }
  };

  const handleDeleteRow = async (index) => {
    const rowToDelete = index;
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${rowToDelete.name}"?`
    );
    if (confirmDelete) {
      try {
        const newRows = await deleteData(
          `webinar/deleteWebinarUser${rowToDelete._id}`,
          config
        );
        console.log(newRows);
        fetchRows();
        toast.success("Row deleted successfully!"); // Show success toaster
      } catch (error) {
        toast.error("Error deleting row!"); // Show error toaster
        console.error("Error deleting row:", error);
      }
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * rowsPerPage;
  const currentPageData = rows.slice(offset, offset + rowsPerPage);
  const pageCount = Math.ceil(rows.length / rowsPerPage);

  return (
    <Container className="mt-4">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>#</th>
            <th style={{ width: "20%" }}>Webinar Title</th>
            <th style={{ width: "20%" }}>Name</th>
            <th style={{ width: "20%" }}>Email</th>
            <th style={{ width: "20%" }}>Contact</th>
            <th style={{ width: "20%" }}>Register Date</th>
            <th style={{ width: "30%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((row, index) => (
            <tr key={row._id}>
              <td>{offset + index + 1}</td>
              <td>{row.webinarTitle}</td>
              <td>{row.name}</td>
              <td>{row.email}</td>
              <td>{row.contact}</td>
              <td>{formatDate(row.registerAt)}</td>

              <td>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteRow(row)}
                  className="ms-2"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination Component */}
      {rows.length > rowsPerPage && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      )}
    </Container>
  );
};

export default WebinarRegisterUser;
