import React, { useState, useEffect ,useContext} from 'react';
import { Modal, Button, Form, Container, Table } from 'react-bootstrap'; // Import Bootstrap components
import ReactPaginate from 'react-paginate'; // Import pagination component
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import  constants  from '../../services/constants';
import { getDataAuth, createData,UpdateData, deleteData } from './../../services/routes.services';
import { AuthContext } from "../../context/AuthContext";

const BookSession = () => {
    const [rows, setRows] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isData, setData] = useState('');
    const [currentPage, setCurrentPage] = useState(0); // For pagination
    const rowsPerPage = 10; // Items per page
    const { user } = useContext(AuthContext);
  
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Assuming token is stored in `user.token`
      },
    };
    useEffect(() => {
        fetchRows();
    }, []);
  
    const fetchRows = async () => {
        try {
            const response = await getDataAuth('booksession',config); // Adjust the URL to your API endpoint
            setRows(response.data.booksessions); // Assuming response.data contains the rows
        } catch (error) {
            console.error('Error fetching rows:', error);
        }
    };
    const handleViewRow =(rowData)=>{
        setIsFormOpen(true);
        setData(rowData)
    }
    const handleClose = () => {
        setIsFormOpen(false);
    };
    const handlePageClick = (data) => {
        setCurrentPage(data.selected); // Update the current page when the user clicks
    };
    const handleDeleteRow = async (index) => {
        const rowToDelete = index;
        const confirmDelete = window.confirm(`Are you sure you want to delete "${rowToDelete.name}"?`);
        if (confirmDelete) {
            try {
                const newRows = await deleteData(`booksession/${rowToDelete._id}`,config);
                
                toast.success('Row deleted successfully!'); // Show success toaster
                fetchRows();
            } catch (error) {
                toast.error('Error deleting row!'); // Show error toaster
                console.error('Error deleting row:', error);
            }
        }
    };
    // Pagination logic
const offset = currentPage * rowsPerPage;
const currentPageData = rows.slice(offset, offset + rowsPerPage);
const pageCount = Math.ceil(rows.length / rowsPerPage);
return (
    <Container className="mt-4">
        
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

        <Modal show={isFormOpen} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Book A Session Query by {isData.name}</Modal.Title>
               
            </Modal.Header>
            <Modal.Body>
            <Table>
                 
                 <tr>
                     <td>Name : </td>
                     <td>{isData.name}</td>
                 </tr>
                 <tr>
                     <td>Contact : </td>
                     <td>{isData.contact}</td>
                 </tr>
                 <tr>
                     <td>Email : </td>
                     <td>{isData.email}</td>
                 </tr>
                 <tr>
                     <td>City : </td>
                     <td>{isData.city}</td>
                 </tr>
                 <tr>
                     <td>Message : </td>
                     <td>{isData.message}</td>
                 </tr>
                     
                     
                     </Table>
            </Modal.Body>
        </Modal>

        <Table striped bordered hover className="mt-4">
            <thead>
                <tr>
                    <th>#</th>
                    <th> Name</th>
                    <th> Message</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {currentPageData.map((row, index) => (
                    <tr key={row._id}>
                        <td>{offset + index + 1}</td>
                        <td>{row.name}</td> {/* Fixed to use row.title */}
                        <td dangerouslySetInnerHTML={{ __html: row.message }}></td>
                        <td>
                            <Button variant="warning" onClick={() => handleViewRow(row)}>View</Button>
                            <Button variant="danger" onClick={() => handleDeleteRow(row)} className="ms-2">Delete</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

        {/* Pagination Component */}
        {rows.length > rowsPerPage && (
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        )}
    </Container>
);
}
export default BookSession;