import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { IoMdPlay, IoMdClose } from "react-icons/io";
import constants from "../../services/constants";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
import axios from 'axios';


const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHENNAL_ID;

function VideosComponent() {
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.images : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.title : ''; 
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [videos, setVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const itemsPerPage = 7; // Videos per page

    useEffect(() => {
        const fetchVideoIds = async () => {
            try {
                const searchApiUrl = `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=50`;
                const searchResponse = await axios.get(searchApiUrl);
                const videoItems = searchResponse.data.items;
                setVideos(videoItems);
            } catch (error) {
                console.error('Error fetching data from YouTube API', error);
            }
        };
        fetchVideoIds();
    }, []);

    const playVideoHandler = (videoId) => {
        setCurrentVideo(videoId);
        setIsPlaying(true);
    };

    const closeVideoHandler = () => {
        setCurrentVideo(null);
        setIsPlaying(false);
    };

    // Pagination Logic
    const totalPages = Math.ceil(videos.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentVideos = videos.slice(startIndex, endIndex);

    const goToPreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
        window.scrollTo(0, 0);
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
        window.scrollTo(0, 0);
    };

    return (
        <div className={Styles.videoPage + " " + Styles.topSpace}>
            <CommonBanner bannerImg={constants.Image_BASE_URL + "" + BannerImage} bannerTitle={BannerTitle} />
            <BreadcrumbComponent home="Home" listOne="Videos" />

            {/* Main Video Section */}
            <div className={Styles.videoBlock}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className={Styles.centerTitle}>Watch <span>Videos</span></h3>
                            <div className={Styles.newVideo}>
                                <figure>
                                    <img src={videos[0]?.snippet?.thumbnails?.high?.url} alt="Main Video Thumbnail" />
                                </figure>
                                <div className={Styles.content}>
                                    <h4>{videos[0]?.snippet?.title || "Welcome to Ananta"}</h4>
                                    <h6>{videos[0]?.snippet?.description || "What Parents Need to Know"}</h6>
                                </div>
                                <button
                                    type="button"
                                    className={Styles.playBtn}
                                    onClick={() => playVideoHandler(videos[0]?.id?.videoId)}
                                >
                                    <span><IoMdPlay /></span>Click to play
                                </button>
                                {isPlaying && currentVideo === videos[0]?.id?.videoId && (
                                    <div className={Styles.communityVideo}>
                                        <div className={Styles.videoCont}>
                                            <button className={Styles.closeVideo} onClick={closeVideoHandler}>
                                                <IoMdClose />
                                            </button>
                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/${currentVideo}?autoplay=1`}
                                                frameBorder="0"
                                                allow="autoplay; encrypted-media"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* More Videos Section */}
            <div className={Styles.moreVideoBlock}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className={Styles.centerTitle}>More <span>Videos</span></h3>
                            <div className={Styles.moreVideoSec}>
                                {currentVideos.slice(1).map((video, index) => (
                                    <div className={Styles.newVideo} key={index}>
                                        <figure>
                                            <img
                                                src={video.snippet.thumbnails.medium.url}
                                                alt={video.snippet.title}
                                            />
                                        </figure>
                                        <div className={Styles.content}>
                                            <h6>{video.snippet.title}</h6>
                                        </div>
                                        <button
                                            type="button"
                                            className={Styles.playBtn}
                                            onClick={() => playVideoHandler(video.id?.videoId || video.id)}
                                        >
                                            <span><IoMdPlay /></span>Click to play
                                        </button>
                                        {isPlaying && currentVideo === video.id?.videoId && (
                                            <div className={Styles.communityVideo}>
                                                <div className={Styles.videoCont}>
                                                    <button className={Styles.closeVideo} onClick={closeVideoHandler}>
                                                        <IoMdClose />
                                                    </button>
                                                    <iframe
                                                        width="100%"
                                                        height="100%"
                                                        src={`https://www.youtube.com/embed/${currentVideo}?autoplay=1`}
                                                        frameBorder="0"
                                                        allow="autoplay; encrypted-media"
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {/* Pagination Controls */}
                            <div className={Styles.courseBtn}>
                                <div className={Styles.pagination}>
                                    <button className={Styles.firstBtn} onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>&laquo; </button>
                                    <button className={Styles.prevBtn} onClick={goToPreviousPage} disabled={currentPage === 1}>&lt; </button>
                                    <span>{currentPage} of {totalPages}</span>
                                    <button className={Styles.nextBtn} onClick={goToNextPage} disabled={currentPage === totalPages}>&gt;</button>
                                    <button className={Styles.lastBtn} onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>&raquo;</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default VideosComponent;
