import React from 'react';

function Loader(){
    return(
        <div className='pageLoader'>
            <img src={require('../../../assest/images/logo.png')} />
        </div>
    )
}

export default Loader;