import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Style from "./Login.module.css"; // Import CSS file
import constants from "../../services/constants";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${constants.API_BASE_URL}auth/register`, formData);
      navigate("/login");
    } catch (err) {
      setError(err.response?.data?.message || "Registration failed");
    }
  };

  return (
    <div className={Style.loginContainer}>
      <h4>Register</h4>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit} className={Style.loginForm}>
        <div className={Style.formGroup}>
          <label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              onChange={handleChange}
              className={Style.formInput}
              required
            />
          </label>
        </div>

        <div className={Style.formGroup}>
          <label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              className={Style.formInput}
              required
            />
          </label>
        </div>
        <div className={Style.formGroup}>
          <label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              className={Style.formInput}
              required
            />
          </label>
        </div>
        <button type="submit" className={Style.loginButton}>
          SignUp
        </button>
      </form>
    </div>
  );
};

export default Register;
