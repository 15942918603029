import { useState, useEffect } from 'react';
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import parse from 'html-react-parser';
import AOS from "aos";
import "aos/dist/aos.css";

function LegacyComponents({ legacyDtat, interval = 3000 }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState("fade-in");
    const texts = ["Evolve.", "Elevate.", "Thrive."];
    const TextLimit = 300;
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 0 },
            items: 1,
        },
    };

    useEffect(() => {
        const textChangeInterval = setInterval(() => {
            setFadeClass("fade-out");
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
                setFadeClass("fade-in");
            }, 500); // Matches the fade-out duration
        }, interval);

        return () => clearInterval(textChangeInterval);
    }, [texts, interval]);
    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return (
        <div className={Styles.legacySec}>
            <Container>
                <Row>
                    <Col>
                        <div className={Styles.legacyContainer}>
                            <h3 data-aos="fade-up">Shared <span>Legacy</span></h3>
                            <h2 className={`${Styles.animeText} ${Styles[fadeClass]}`} data-aos="fade-up" data-aos-delay="100">
                                {texts[currentIndex]}
                            </h2>
                            <h6 data-aos="fade-up" data-aos-delay="200">Revolutionizing parenting and raising wise</h6>
                            {legacyDtat?.length > 0 && (
                                <div className={'legacySlider ' + Styles.legacyOwl} data-aos="fade-up" data-aos-delay="300">
                                    <Carousel
                                        responsive={responsive}
                                        transitionDuration={500}
                                        autoPlay
                                        infinite
                                        arrows
                                        className="blackArrow"
                                    >
                                        {legacyDtat.map((item, index) => (
                                            <div className={Styles.item} key={index}>
                                                <div>
                                                {parse(`${item.description.length > TextLimit ? item.description.substring(0, TextLimit) + "..." : item.description}`)}
                                                   
                                                    <h5>{parse(item.authore || "")}</h5>
                                                </div>
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default LegacyComponents;
