import React, { useState, useEffect, useRef } from "react";
import Styles from "../Styles/Common.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { IoIosArrowRoundForward } from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {
  SubmiteDataSession,
  fetchCsrfToken,
} from "./../../services/routes.services";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { useLocation } from "react-router-dom";
import Loader from "../Common/PageLoader/Loader";

import constants from "../../services/constants";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);
};
const fetchCities = async () => {
  // Mock data or replace with an actual API call
  return [
    { id: "Mumbai", name: "Mumbai" },
    { id: "Delhi", name: "Delhi" },
    { id: "Bangalore", name: "Bangalore" },
    { id: "Hyderabad", name: "Hyderabad" },
    { id: "Chennai", name: "Chennai" },
    { id: "Kolkata", name: "Kolkata" },
    { id: "Ahmedabad", name: "Ahmedabad" },
    { id: "Pune", name: "Pune" },
    { id: "Jaipur", name: "Jaipur" },
    { id: "Lucknow", name: "Lucknow" },
    { id: "Kanpur", name: "Kanpur" },
    { id: "Nagpur", name: "Nagpur" },
    { id: "Indore", name: "Indore" },
    { id: "Thane", name: "Thane" },
    { id: "Bhopal", name: "Bhopal" },
    { id: "Visakhapatnam", name: "Visakhapatnam" },
    { id: "Patna", name: "Patna" },
    { id: "Vadodara", name: "Vadodara" },
    { id: "Faridabad", name: "Faridabad" },
    { id: "Gurugram", name: "GuruGram" },
    { id: "Ghaziabad", name: "Ghaziabad" },
    { id: "Ludhiana", name: "Ludhiana" },
  ];
};

function BookingSessionComponent() {
  const formRef = useRef(null);
  const location = useLocation();
  const { webinarDetail } = location.state || {};
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState({});

  const [cities, setCities] = useState([]);

  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    city: "",
    message: "",
  });

  const config = {
    headers: { "X-CSRF-Token": csrfToken },
  };
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await fetchCsrfToken("booksession/get-csrf-token", {
          withCredentials: true,
        });
        console.log("yyyyy", token.data.csrfToken);
        setCsrfToken(token.data.csrfToken);
      } catch (error) {
        alert("Failed to fetch CSRF token.");
      }
    };
    getToken();
  }, []);
  useEffect(() => {
    // Fetch cities on component mount
    const loadCities = async () => {
      const cityData = await fetchCities();
      // Sort cities alphabetically by name
      const sortedCities = cityData.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setCities(sortedCities);
    };
    loadCities();
  }, []);
  useEffect(() => {
    setLoading(false);
    // Check if navigation state indicates focus on form
    if (location.state?.focus) {
      // Ensure scrolling occurs after the page renders
      setTimeout(() => {
        formRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        formRef.current?.focus();
      }, 100); // Delay to ensure DOM is rendered
    }
  }, [location]);
  if (loading) {
    return <Loader />;
  }

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // Check if reCAPTCHA succeeded
  };

  // Validation function
  const validateForm = () => {
    const { name, email, contact, city, message } = formData;
    const newErrors = {};

    if (!name) newErrors.name = "Name field is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else {
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = "Invalid email format";
      }
    }
    if (!contact) {
      newErrors.contact = "Mobile number is required";
    } else {
      // Regex for validating a 10-digit mobile number
      //const mobileRegex = /^\d{10}$/;
      const mobileRegex = /^\+?[1-9]\d{1,14}$/;
      if (!mobileRegex.test(contact)) {
        newErrors.contact = "Mobile number must be 10 digits";
      }
    }

    if (!city) newErrors.city = "City field is required";
    if (!message) newErrors.message = "Message field is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    // if (!captchaVerified) {
    //     alert("Please verify the CAPTCHA.");
    //     return;
    //   }
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${constants.API_BASE_URL}booksession`,
          formData,
          config
        );
        if (response.status === 201) {
          setFormData({
            name: "",
            email: "",
            contact: "",
            city: "",
            message: "",
          });
          toast.success("Booked Your Session successfully!");
        } else {
          toast.error("Error saving row! Please check your form");
        }
      } catch (error) {
        toast.error("Error saving row!");
      }
    } else {
      return false;
    }
  };
  console.log("csrfToken===", csrfToken);
  return (
    <div className={Styles.bookingPage + " " + Styles.topSpace}>
      <div className={Styles.brownBg}>
        <BreadcrumbComponent home="Home" listOne="Book a session" />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />

      <div className={Styles.bookingBlock}>
        <div className={Styles.bookBanner}>
          <h3 className={Styles.centerTitle}>
            <span>Book a session</span>
          </h3>
          <div className={Styles.bookContainer}>
            <figure>
              <img src={require("../../assest/images/happy-couple.png")} />
            </figure>
            <div className={Styles.content}>
              <h4>Parenting Partner and each Kid's Ally:</h4>
              <p>
                Parenting is not about raising a child, it is about raising a
                human being that will change the world.
              </p>
            </div>
          </div>
        </div>
        <div className={Styles.bookForm}>
          <Container>
            <Row>
              <Col>
                <div className={Styles.formContainer}>
                  <div className={Styles.group}>
                    <div className={Styles.filed}>
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        onChange={handleChange}
                        value={formData.name}
                        required
                      />
                      {errors.name && <span className={Styles.errorMsg}>{errors.name}</span>}
                    </div>
                    <div className={Styles.filed}>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                        value={formData.email}
                        required
                      />
                      {errors.email && <span className={Styles.errorMsg}>{errors.email}</span>}
                    </div>
                  </div>
                  <div className={Styles.group}>
                    <div className={Styles.filed}>
                      <input
                        type="phone"
                        name="contact"
                        placeholder="Contact No."
                        onChange={handleChange}
                        value={formData.contact}
                        required
                      />
                      {errors.contact && <span className={Styles.errorMsg}>{errors.contact}</span>}
                    </div>
                    <div className={Styles.filed}>
                      <select
                        value={formData.city}
                        name="city"
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Your City *</option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                      {errors.city && <span className={Styles.errorMsg}>{errors.city}</span>}
                    </div>
                  </div>
                  <div className={Styles.group}>
                    <div className={Styles.filed}>
                      <textarea
                        placeholder="Message"
                        name="message"
                        onChange={handleChange}
                        value={formData.message}
                        required
                      ></textarea>
                      {errors.message && <span className={Styles.errorMsg}>{errors.message}</span>}
                    </div>
                  </div>
                  {/* <div className={Styles.group}>
                                        <ReCAPTCHA
                                            sitekey="YOUR_SITE_KEY" // Replace with your reCAPTCHA site key
                                            onChange={handleCaptchaChange}
                                        />
                                    </div>           */}
                  <div className={Styles.group}>
                    <div className={Styles.filed}>
                      <button type="button" onClick={handleSubmit}>
                        Submit <IoIosArrowRoundForward />
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default BookingSessionComponent;
