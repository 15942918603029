import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import constants from "../../services/constants";
import parse from 'html-react-parser';
import { IoMdClose } from "react-icons/io";
import YoutubeSubscribres from "./YoutubeSubscribres";
import AOS from "aos";
import "aos/dist/aos.css";

function FounderComponent (props){
    const mediaData = props.mediaData;
    const founderData = props.founderData;
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [isPlaying, setIsPlaying] = useState(false);
    const PlayVideoHandler = () => {
        setIsPlaying(!isPlaying);
    };
    //Image Size Loader
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return(
        <div className={Styles.founderSec}>
            <div className={Styles.founderBg}>
                <Container>
                    <Row>
                        <Col md={6}>
                             <div className={Styles.founderProfile}>
                                <figure>
                                    <img src={constants.Image_BASE_URL+""+founderData.images} alt="Dr. Shreya Dubey" onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                </figure>
                                <button type="button" onClick={PlayVideoHandler} className={Styles.founderVide}><img src={require('../../assest/images/video-Icon.png')} /></button>
                                {isPlaying && (
                                    <div className={Styles.communityVideo}>
                                        <div className={Styles.videoCont}>
                                            <button className={Styles.closeVideo} onClick={PlayVideoHandler}>
                                                <IoMdClose />
                                            </button>
                                            <video width="100%" height="100%" controls autoPlay>
                                                <source src={constants.Image_BASE_URL+""+founderData.video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                )}
                             </div>
                        </Col>
                        <Col md={6}>
                            <div className={Styles.founderContent}>
                                <h2 data-aos="fade-up">Know <span>the Founder</span></h2>
                                <div data-aos="fade-up" data-aos-delay="200">{parse(`${founderData.description}`)}</div>
                                <div className={Styles.flexBtn} data-aos="fade-up" data-aos-delay="400">
                                    <Link to='/about' title="Ananta Founder" className={Styles.pinkBtn + " " + Styles.commonBtn}>Our Vision <IoIosArrowRoundForward /></Link>
                                    <Link to='/about' title="Ananta Founder" className={Styles.pinkBtn + " " + Styles.commonBtn}>Our Mission <IoIosArrowRoundForward /></Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
            <div className={Styles.socialSec}>
                <Container>
                    <Row>
                        <Col>
                            <ul className={Styles.socialList}>
                               
                                {/* {mediaData &&
                                    mediaData.map((item, index)=>{
                                        return(
                                            <li key={index}>
                                                <img src={constants.Image_BASE_URL+""+item.images} />
                                                <span>{parse(`${item.description}`)}</span>
                                            </li>
                                        )
                                    })
                                } */}
                                 <li data-aos="fade-up">
                                    <img src={require('../../assest/images/fb_img.webp')} />
                                    <span>35.2K followers</span>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="50">
                                    <img src={require('../../assest/images/insta_img.webp')} />
                                    <span>29.2K followers</span>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="100">
                                    <img src={require('../../assest/images/youtube_img.webp')} />
                                    <span> <YoutubeSubscribres/></span>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="150">
                                    <img src={require('../../assest/images/linkedin-img.webp')} />
                                    <span>29.2K followers</span>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )           
}

export default FounderComponent;