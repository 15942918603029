import config from '../../src/config';

const constants = {};

if (window.location.hostname === "ananta") {
  constants.API_BASE_URL = ''; // Base url
} else {
  constants.API_BASE_URL = config.API_BASE_URL;
  constants.Image_BASE_URL = config.Image_BASE_URL;
  constants.File_BASE_URL = config.File_BASE_URL;
}

export default constants;
