// src/Admin/AdminLayout/AdminLayout.jsx
import React, { useState, useContext } from "react";
import { Outlet, NavLink, useNavigate } from "react-router-dom"; // Use NavLink for active link styles
import Style from "./AdminLayout.module.css"; // Import the CSS file
import Logo from "../../assest/images/logo.png";
import { AiOutlineLogout } from "react-icons/ai";
import { AuthContext } from "../../context/AuthContext";

const AdminLayout = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
  };

  return (
    <div className={Style.adminLayout}>
      <aside className={Style.sidebar}>
        <ul>
          <li>
            <NavLink
              to="/admin/dashboard"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              } // Apply active class
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/header"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              } // Apply active class
            >
              Header
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/home"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/aboutus"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              }
            >
              About US
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/blog"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              }
            >
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/media"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              }
            >
              Media
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/course"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              }
            >
              Course
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/community"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              }
            >
             Ananta Community
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/booksessionlist"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              }
            >
             Book A Session Listing
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/teams"
              className={({ isActive }) =>
                isActive ? Style.activeLink : Style.link
              }
            >
            My Team
            </NavLink>
          </li>
        </ul>
      </aside>
      <main className={Style.mainContent}>
        <header className={Style.adminHeader}>
          <div className={Style.logo}>
            <img
              src={Logo}
              alt="Ananta"
              onLoad={handleImageLoad}
              width={imageDimensions.width}
              height={imageDimensions.height}
              style={{ boxShadow: "0px 3px 1px rgba(172 50 108 / 26%)" }}
            />
            {user && (
              <a href="#" onClick={logout} className={Style.logout}>
                <AiOutlineLogout />
              </a>
            )}
          </div>
        </header>
        <div className={Style.content}>
          <Outlet /> {/* Render nested routes here */}
        </div>
      </main>
    </div>
  );
};

export default AdminLayout;
