import React, { useState, useEffect,useContext } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Modal, Button, Form, Container, Table } from 'react-bootstrap'; // Import Bootstrap components
import ReactPaginate from 'react-paginate'; // Import pagination component
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import  constants  from '../../services/constants';
import { getData, createData,UpdateData, deleteData } from '../../services/routes.services';
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

const Community = () => {
    const [rows, setRows] = useState([]);
    const [newItem, setNewItem] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [newDescription2, setNewDescription2] = useState(''); // State for description2
    const [newLink, setNewLink] = useState(''); // State for  link
    const [newExtraLink, setNewExtraLink] = useState(''); // State for  link
    const [newOrder, setNewOrder] = useState('');
    const [newDate, setNewDate] = useState('');
   const [newLocation, setNewLocation] = useState('');
    const [isDataOpen, setIsDataOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null); // Track index for editing
    const [images, setImages] = useState(['']); // Initialize with empty image strings
    const [detailPageImages, setDetailPageImages] = useState(['']); // Initialize with empty image strings
    const [video, setVideo] = useState(''); // State to store video data
    const [videoPath, setVideoPath] = useState("");
    const [totalChunks, setTotalChunks] = useState(0);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [videoPreview, setVideoPreview] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // For pagination
    const rowsPerPage = 10; // Items per page
    const uniqueFilename = file ? `${Date.now()}-${file.name}` : "";
    const { user } = useContext(AuthContext);

    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`, // Assuming token is stored in `user.token`
      },
    };
    useEffect(() => {
        fetchRows();
    }, []);

    const fetchRows = async () => {
        try {
            const response = await getData('community'); // Adjust the URL to your API endpoint
         
            const data = response.data.communitys;
            setRows(data);
        } catch (error) {
            console.error('Error fetching rows:', error);
        }
    };

    const handleAddRow = async (e) => {
  
        e.preventDefault();
        let videoPath='';
        console.log(file)
        if (file) {
         videoPath = await uploadChunks(file, uniqueFilename);
        }
    
        if (newItem && newDescription) {
            const formData = new FormData();
            formData.append('title', newItem);
            formData.append('date', newDate);
            formData.append('location', newLocation);
            formData.append('description', newDescription);
            formData.append('description2', newDescription2);
            formData.append('link', newLink);
            formData.append('extralink', newExtraLink);
            formData.append('order', newOrder);
            formData.append('video', videoPath);
           
            // Check if there is an image file to upload
           
                if (images) {
                    formData.append(`images`, images); // Append each file object directly
                }
                if (detailPageImages) {
                    formData.append(`detailimages`, detailPageImages); // Append each file object directly
                }
                for (let [key, value] of formData.entries()) {
                    console.log(`${key}:`, value);
                }
            try {
                if (editIndex !== null) {
                    
                    await UpdateData(`community/${editIndex._id}`, formData,config); // Adjust for your PUT endpoint
                    const updatedRows = [...rows];
                    updatedRows[editIndex] = { ...updatedRows[editIndex], ...formData }; // Update the local state
                    setRows(updatedRows);

                    toast.success('Row updated successfully!');
                    fetchRows();
                } else {
                    const response = await createData('community', formData,config);
                    
                 toast.success('Row Added successfully!');
                 fetchRows();
                }
                resetForm();
            } catch (error) {
                console.error('Error saving row:', error);
                toast.error('Error saving row!');
            }
        }
    };

    const resetForm = () => {
        setNewItem('');
        setNewDescription('');
        setNewDate('');
        setNewLocation('');
        setNewDescription2(''); // Reset vision state
        setNewExtraLink('');
        setNewLink(''); // Reset mission state
        setNewOrder('')
        setImages(['']);
        setDetailPageImages([''])
        setVideo(''); // Reset video state
        setEditIndex(null);
        setIsDataOpen(false);
    };

    const handleEditRow = (index) => {
        const row = index;
        setNewItem(row.title);
        setNewDescription(row.description);
        setNewDescription2(row.description2);
        setNewLink(row.link);
        setNewExtraLink(row.extralink);
        setNewOrder(row.order);
        setImages(row.images);
        let formattedDate = "";
        if (row.date) {
            const parsedDate = new Date(row.date);
            formattedDate = parsedDate.toISOString().split("T")[0]; // Extracts "YYYY-MM-DD"
        }
        setNewDate(formattedDate);
        setDetailPageImages(row.detailimages)
       setNewLocation(row.location);
        setVideo(row.video); // Load video into state
        setEditIndex(index);
        setIsDataOpen(true);
    };

    const handleClose = () => {
        resetForm();
    };

    const handleDeleteRow = async (index) => {
        const rowToDelete = index;
        const confirmDelete = window.confirm(`Are you sure you want to delete "${rowToDelete.title}"?`);
        if (confirmDelete) {
           
            try {
                const newRows = await deleteData(`community/${rowToDelete._id}`,config);
                console.log(newRows);
                fetchRows();
                toast.success('Row deleted successfully!'); // Show success toaster
            } catch (error) {
                toast.error('Error deleting row!'); // Show error toaster
                console.error('Error deleting row:', error);
            }
       
        }
    };

    const handleImageChange = (index, e) => {
        if (e && e.target && e.target.files[0]) {  // Check for the existence of e and e.target
            const file = e.target.files[0];
            setImages(file); // Store the image file
        }
    };
    const handleDetailImageChange = (index, e) => {
        if (e && e.target && e.target.files[0]) {  // Check for the existence of e and e.target
            const file = e.target.files[0];
            setDetailPageImages(file); // Store the image file
        }
    };
    function truncateText(text, maxLength) {
        return text.length > maxLength
          ? text.substring(0, maxLength) + "..."
          : text;
      }
    const handleVideoChange = (e) => {
        setVideo(e.target.files[0]);
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setVideoPreview(URL.createObjectURL(e.target.files[0])); // Generate preview URL
    // Calculate total chunks
    const chunkSize = 10 * 1024 * 1024; // 10MB per chunk
    const chunks = Math.ceil(selectedFile.size / chunkSize);
    setTotalChunks(chunks);
    };
    const uploadChunks = async (file, uniqueFilename) => {
        const chunkSize = 2 * 1024 * 1024; // 10MB per chunk
        const totalChunks = Math.ceil(file.size / chunkSize);
    
        // Upload each chunk
        for (let i = 0; i < totalChunks; i++) {
          const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
          const formData = new FormData();
          formData.append("chunk", chunk);
          formData.append("filename", uniqueFilename);
          formData.append("chunkNumber", i + 1);
          formData.append("totalChunks", totalChunks);
    
          try {
            await axios.post(
              `${constants.API_BASE_URL}founder/upload-chunk`,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                  const percent = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgress(percent);
                },
              }
            );
          } catch (error) {
            console.error("Error uploading chunk", error);
            throw error;
          }
        }
    
        // After all chunks are uploaded, set the video path
    
        setVideoPath(`${uniqueFilename}`); // Set the video path for saving in the founder data
        return `${uniqueFilename}`;
      };
    useEffect(() => {
        // Generate the slug and set as link whenever the title (newItem) changes
        const slug = generateSlug(newItem);
        setNewLink(`${slug}`);
    }, [newItem]);

    const generateSlug = (title) => {
        return title
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '') // Remove special characters
            .replace(/\s+/g, '-'); // Replace spaces with hyphens
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const offset = currentPage * rowsPerPage;
    const currentPageData = rows.slice(offset, offset + rowsPerPage);
    const pageCount = Math.ceil(rows.length / rowsPerPage);

    return (
        <Container className="mt-4">
            <Button variant="primary" className="mb-3" onClick={() => setIsDataOpen(true)}>
                Add Entry
            </Button>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

            <Modal show={isDataOpen} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex !== null ? `Edit ${newItem}` : 'Add New community'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleAddRow}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control
                                type="text"
                                value={newItem}
                                onChange={(e) => setNewItem(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Order:</Form.Label>
                            <Form.Control
                                type="number"
                                value={newOrder}
                                onChange={(e) =>setNewOrder(e.target.value)}
                                
                            />
                        </Form.Group>
                       
                <Form.Group className="mb-3">
                    <Form.Label> Date:</Form.Label>
                    <Form.Control
                        type="date"
                        value={newDate}
                        onChange={(e) => setNewDate(e.target.value)}
                        required
                    />
                </Form.Group>
              
                <Form.Group className="mb-3">
                    <Form.Label>Location:</Form.Label>
                    <Form.Control
                        type="text"
                        value={newLocation}
                        onChange={(e) => setNewLocation(e.target.value)}
                        required
                    />
                </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description:</Form.Label>
                            <ReactQuill
                                theme="snow"
                                value={newDescription}
                                onChange={setNewDescription}
                                required
                            />
                        </Form.Group>
                          {/* Our Description2 Input */}
                          <Form.Group className="mb-3">
                            <Form.Label>Description 2:</Form.Label>
                            <ReactQuill
                                theme="snow"
                                value={newDescription2}
                                onChange={setNewDescription2}
                                required
                            />
                        </Form.Group>
                    
                        {/* Our Link Input */}
                        <Form.Group className="mb-3">
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={newLink}
                                onChange={(e) => setNewLink(e.target.value)}
                                required
                            />
                        </Form.Group>
                         {/* Our Link Input */}
                         <Form.Group className="mb-3">
                            <Form.Label>Add More Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={newExtraLink}
                                onChange={(e) => setNewExtraLink(e.target.value)}
                                required
                            />
                        </Form.Group>
                        
                        {/* Image Upload Section */}
                      
                            <Form.Group className="mb-3" >
                                <Form.Label>Upload Image :</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => handleImageChange(0, e)}
                                    accept="image/*"
                                />
                                {images[0]!='' && <img src={`${constants.Image_BASE_URL}${images}`} alt={`Preview`} style={{ width: '20%', marginTop: '10px' }} />}
                            </Form.Group>
                     {/* Image Upload Section */}
                      
                     <Form.Group className="mb-3" >
                                <Form.Label>Upload Deatil Page Image :</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => handleDetailImageChange(0, e)}
                                    accept="image/*"
                                />
                                {detailPageImages[0]!='' && <img src={`${constants.Image_BASE_URL}${detailPageImages}`} alt={`Preview`} style={{ width: '20%', marginTop: '10px' }} />}
                            </Form.Group>
                     


                        {/* Video Upload Section */}
                        <Form.Group className="mb-3">
                            <Form.Label>Upload Video:</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleVideoChange}
                                accept="video/*"
                            />
                            {!videoPreview && video && (
                <video
                  src={`${constants.Image_BASE_URL}${video}`}
                  controls
                  style={{ width: "100%", marginTop: "10px" }}
                />
              )}
              {videoPreview && (
                <video
                  src={`${videoPreview}`}
                  controls
                  style={{ width: "100%", marginTop: "10px" }}
                />
              )}
                        </Form.Group>

                        <Button type="submit" variant="success">{editIndex !== null ? 'Update' : 'Submit'}</Button>
                        <Button variant="secondary" onClick={handleClose} className="ms-2">Cancel</Button>
                        <div>{uploading && <p>Uploading... {progress}%</p>}</div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Table striped bordered hover className="mt-4">
                <thead>
                    <tr>
                    <th style={{ width: "5%" }}>#</th>
            <th style={{ width: "20%" }}>Title</th>
            <th style={{ width: "45%" }}>Description</th>
            <th style={{ width: "30%" }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData.map((row, index) => (
                        <tr key={row._id}>
                            <td>{offset + index + 1}</td>
                            <td>{row.title}</td>
                            <td dangerouslySetInnerHTML={{ __html: truncateText(row.description,100) }}></td>
                           
                            <td>
                                <Button variant="warning" onClick={() => handleEditRow(row)}>Edit</Button>
                                <Button variant="danger" onClick={() => handleDeleteRow(row)} className="ms-2">Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Pagination Component */}
            {rows.length > rowsPerPage && (
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            )}
        </Container>
    );
};

export default Community;
