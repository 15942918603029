import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import constants from "../../services/constants";
import AOS from "aos";
import "aos/dist/aos.css";

function OurCoursesComponents (props){
    const courseData = props.courseData;
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 1800 },
          items: 5.3
        },
        desktop: {
          breakpoint: { max: 1800, min: 1024 },
          items: 3.5
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight
        });
    };
    useEffect(() => {
        AOS.init({
          duration: 1000,
          offset: 100,  
          easing: "ease-in-out", 
          once: true,
        });
      }, []);
    return(
        <div className={Styles.coursesSec}>
            <Container>
                <Row>
                    <Col>
                        <h3 data-aos="fade-up">Our <span>Courses</span></h3>
                    </Col>
                </Row>
            </Container>
            <div className={'coursesOwl ' + Styles.coursesOwlSlider} data-aos="fade-up" data-aos-delay="100">
                <Carousel responsive={responsive} transitionDuration={500} autoPlay={true} infinite={true} arrows={false}>
                    {courseData &&
                        courseData.map((item, index)=>{
                            return(
                                <div className={Styles.courseItem} key={index}>
                                    <figure>
                                        <img src={constants.File_BASE_URL+""+item.images} alt={item.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                    </figure>
                                    <div className={Styles.courseName}>
                                        <h5>{item.title}</h5>
                                        <Link to={{
                                            pathname : `/course-detail/${item.link}`,
                                        }}
                                        //state={{title: item.title, description: item.description, curriculum: item.curriculum, duration: item.duration, images: item.images }}
                                        title={item.link}><img src={require('../../assest/images/Link_arrow.webp')} alt="link arrow" /></Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>
            <div className={Styles.courseBtn} data-aos="fade-up" data-aos-delay="200">
                <Link to='/courses' title="View all" className={Styles.pinkBtn + " " + Styles.commonBtn}>View all <IoIosArrowRoundForward /></Link>

            </div>
        </div>
    )
}

export default OurCoursesComponents;