import React, { useState, useEffect ,useContext} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Modal, Button, Form, Container, Table } from 'react-bootstrap'; // Import Bootstrap components
import ReactPaginate from 'react-paginate'; // Import pagination component
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import  constants  from '../../../services/constants';
import { getData, createData,UpdateData, deleteData } from '../../../services/routes.services';
import { AuthContext } from "../../../context/AuthContext";

const Legacy = () => {
  const [rows, setRows] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [newAuthore, setNewAuthore] = useState('');
  const [newSubTitle, setNewSubTitle] = useState('');
  const [newLink, setNewLink] = useState('');
  const [newOrder, setNewOrder] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // Track index for editing
  const [images, setImages] = useState(null); // Initialize with null for image file

  const [currentPage, setCurrentPage] = useState(0); // For pagination
  const rowsPerPage = 10; // Items per page
  const { user } = useContext(AuthContext);

  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Assuming token is stored in `user.token`
    },
  };

  useEffect(() => {
      fetchRows();
  }, []);

  const fetchRows = async () => {
      try {
          const response = await getData('legacy'); // Adjust the URL to your API endpoint
          setRows(response.data.legacys); // Assuming response.data contains the rows
      } catch (error) {
          console.error('Error fetching rows:', error);
      }
  };
  const handleAddRow = async (e) => {
    e.preventDefault();
    if (newItem && newDescription) {
        const formData = new FormData();
        formData.append('title', newItem);
        formData.append('subtitle', newSubTitle);
        formData.append('description', newDescription);
        formData.append('authore', newAuthore);
        formData.append('order', newOrder);
        formData.append('link', newLink);

        // Check if there is an image file to upload
        if (images) {
            formData.append('images', images); // Append the file
        }

        try {
            if (editIndex !== null) {
                // Update existing row
                await UpdateData(`legacy/${editIndex._id}`, formData,config); // Adjust for your PUT endpoint
                
                const updatedRows = [...rows];
                updatedRows[editIndex] = { ...updatedRows[editIndex], ...formData }; // Update the local state
                // setRows(response.data.legacys);
                fetchRows();
                toast.success('Row updated successfully!');
            } else {
                // Add new row
                const response = await createData('legacy', formData,config);
                const newRow = response.data; // Axios returns data in response.data
                setRows([...rows, newRow]);
                fetchRows();
                toast.success('Row added successfully!');
            }
            resetForm();
        } catch (error) {
            console.error('Error saving row:', error);
            toast.error('Error saving row!');
        }
    }
};

const resetForm = () => {
    setNewItem('');
    setNewSubTitle('');
    setNewDescription('');
    setNewAuthore('');
    setNewOrder('');
    setNewLink('');
    setImages(null); // Reset the images state
    setEditIndex(null);
    setIsFormOpen(false);
};

const handleEditRow = (index) => {
    const row =index;
    setNewItem(row.title);
    setNewSubTitle(row.subtitle);
    setNewAuthore(row.authore);
    setNewLink(row.lonk);
    setNewOrder(row.order);
    setNewDescription(row.description);
    setImages(row.images); // If you have a URL for images
    setEditIndex(index);
    setIsFormOpen(true);
};

const handleClose = () => {
    resetForm();
};

const handleDeleteRow = async (index) => {
    const rowToDelete = index;
    const confirmDelete = window.confirm(`Are you sure you want to delete "${rowToDelete.title}"?`);
    if (confirmDelete) {
        try {
            await deleteData(`legacy/${rowToDelete._id}`,config);
            
            fetchRows();
            toast.success('Row deleted successfully!'); // Show success toaster
        } catch (error) {
            toast.error('Error deleting row!'); // Show error toaster
            console.error('Error deleting row:', error);
        }
    }
};

const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setImages(file); // Store the image file
    }
};

const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Update the current page when the user clicks
};
useEffect(() => {
  if (newItem) {
      const autoGeneratedLink = `${newItem.toLowerCase().replace(/\s+/g, '-')}`;
      setNewLink(autoGeneratedLink);
  }
}, [newItem]);

// Pagination logic
const offset = currentPage * rowsPerPage;
const currentPageData = rows.slice(offset, offset + rowsPerPage);
const pageCount = Math.ceil(rows.length / rowsPerPage);

return (
    <Container className="mt-4">
        <Button variant="primary" className="mb-3" onClick={() => setIsFormOpen(true)}>
            Add Entry
        </Button>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

        <Modal show={isFormOpen} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{editIndex !== null ? 'Edit Entry' : 'Add New Entry'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleAddRow}>
                    <Form.Group className="mb-3">
                        <Form.Label>Legacy Title:</Form.Label>
                        <Form.Control
                            type="text"
                            value={newItem}
                            onChange={(e) => setNewItem(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Legacy Sub Title:</Form.Label>
                        <Form.Control
                            type="text"
                            value={newSubTitle}
                            onChange={(e) => setNewSubTitle(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Legacy Link:</Form.Label>
                        <Form.Control
                            type="text"
                            value={newLink}
                            onChange={(e) => setNewLink(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Legacy Order:</Form.Label>
                        <Form.Control
                            type="text"
                            value={newOrder}
                            onChange={(e) => setNewOrder(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Legacy Description:</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={newDescription}
                            onChange={setNewDescription}
                        />
                    </Form.Group>
                 
                    <Form.Group className="mb-3">
                        <Form.Label>Legacy Authore:</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={newAuthore}
                            onChange={setNewAuthore}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Upload Legacy Image:</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={handleImageChange}
                            accept="image/*"
                        />
                        {images && <img src={`${constants.File_BASE_URL}${images}`} alt={`Preview`} style={{ width: '20%', marginTop: '10px' }} />}
                    </Form.Group>
                    <Button type="submit" variant="success">{editIndex !== null ? 'Update' : 'Submit'}</Button>
                    <Button variant="secondary" onClick={handleClose} className="ms-2">Cancel</Button>
                </Form>
            </Modal.Body>
        </Modal>

        <Table striped bordered hover className="mt-4">
            <thead>
                <tr>
                    <th style={{ width: "5%" }}>#</th>
                    <th style={{ width: "20%" }}>Legacy Title</th>
                    <th style={{ width: "45%" }}>Legacy Description</th>
                    <th style={{ width: "30%" }}>Actions</th>
                </tr>
            </thead>
            <tbody>
                {currentPageData.map((row, index) => (
                    <tr key={row._id}>
                        <td>{offset + index + 1}</td>
                        <td>{row.title}</td> {/* Fixed to use row.title */}
                        <td dangerouslySetInnerHTML={{ __html: row.description.length > 100 ? row.description.slice(0, 100) + '...' : row.description }}
                        ></td>
                        <td>
                            <Button variant="warning" onClick={() => handleEditRow(row)}>Edit</Button>
                            <Button variant="danger" onClick={() => handleDeleteRow(row)} className="ms-2">Delete</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

        {/* Pagination Component */}
        {rows.length > rowsPerPage && (
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        )}
    </Container>
);
  };
  
  export default Legacy;
  