import React, { useState, useEffect, useRef } from "react";
import Styles from "../Styles/Common.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { IoIosArrowRoundForward } from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";
import {
  SubmiteDataSession,
  fetchCsrfToken,
} from "./../../services/routes.services";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import constants from "../../services/constants";
import axios from "axios";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);
};
function UpcomingWebinarForm() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const location = useLocation();
  const { webinarDetail } = location.state || {};
  console.log("webinaDetai======", webinarDetail);
  const [errors, setErrors] = useState({});
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    webinarId: webinarDetail && webinarDetail._id,
    webinarTitle: webinarDetail && webinarDetail.title,
  });
  const config = {
    headers: { "X-CSRF-Token": csrfToken },
  };
  const webinarDate = new Date(webinarDetail && webinarDetail.startdate);
  const currentDate = new Date();
  if (currentDate >= webinarDate || !webinarDetail) {
    navigate(`/webinars-detail/${webinarDetail && webinarDetail.link}`, {
      state: { webinarDate: webinarDetail && webinarDetail.startdate },
    });
  }
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await fetchCsrfToken("webinar/get-csrf-token", {
          withCredentials: true,
        });
        console.log("yyyyy", token.data.csrfToken);
        setCsrfToken(token.data.csrfToken);
      } catch (error) {
        alert("Failed to fetch CSRF token.");
      }
    };
    getToken();
    if (!webinarDetail) {
      navigate("/webinars");
    }
  }, []);
  useEffect(() => {
    // Check if navigation state indicates focus on form
    if (location.state?.focus) {
      // Ensure scrolling occurs after the page renders
      setTimeout(() => {
        formRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        formRef.current?.focus();
      }, 100); // Delay to ensure DOM is rendered
    }
  }, [location]);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  // Image Size Loader
  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
  };
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  // Validation function
  const validateForm = () => {
    const { name, email, contact } = formData;
    const newErrors = {};

    if (!name) newErrors.name = "Name field is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else {
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = "Invalid email format";
      }
    }
    if (!contact) {
      newErrors.contact = "Mobile number is required";
    } else {
      // Regex for validating a 10-digit mobile number
      //const mobileRegex = /^\d{10}$/;
      const mobileRegex = /^\+?[1-9]\d{1,14}$/;
      if (!mobileRegex.test(contact)) {
        newErrors.contact = "Mobile number must be 10 digits";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const handleSubmit = async (e) => {
    // if (!captchaVerified) {
    //     alert("Please verify the CAPTCHA.");
    //     return;
    //   }
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${constants.API_BASE_URL}webinar/bookwebinar`,
          formData,
          config
        );
        if (response.status === 201) {
          setFormData({
            name: "",
            email: "",
            contact: "",
          });
          toast.success("Your Webinar register successfully!");
        } else {
          toast.error("Error saving row! Please check your form");
        }
      } catch (error) {
        toast.error("Error saving row!");
      }
    } else {
      return false;
    }
  };
  return (
    <div className={Styles.bookingPage + " " + Styles.topSpace}>
      <div className={Styles.brownBg}>
        <BreadcrumbComponent
          home="Home"
          listOne="Enroll for an upcoming webinar "
        />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />

      <div>
        <div className={Styles.webinarForm}>
          <div className={Styles.enrollImg}>
            <figure>
              <img
                src={require("../../assest/images/contact-ananta.jpg")}
                alt=""
                onLoad={handleImageLoad}
                width={imageDimensions.width}
                height={imageDimensions.height}
              />
            </figure>
          </div>
          <div className={Styles.enrollForm}>
            <h3>
              <span>
                Enroll for an <span>upcoming webinar </span>
              </span>
            </h3>
            <h5>{webinarDetail && webinarDetail.title}</h5>
            <p>{webinarDetail && parse(webinarDetail.description)} </p>
            <ul>
              <li>
                <img
                  src={require("../../assest/images/calender_icon.png")}
                  alt="Calender"
                />{" "}
                {webinarDetail && formatDate(webinarDetail.startdate)}{" "}
              </li>
              <li>
                <img
                  src={require("../../assest/images/watch_icon.png")}
                  alt="Time"
                />{" "}
                {webinarDetail && webinarDetail.time}
              </li>
              <li>
                <img
                  src={require("../../assest/images/rupay_icon.png")}
                  alt="Rupay"
                />{" "}
                {webinarDetail && webinarDetail.price}
              </li>
            </ul>
            <div className={Styles.eveningForm}>
              <form ref={formRef}>
                <div className={Styles.eveningGroup}>
                  <div className={Styles.group}>
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      onChange={handleChange}
                      value={formData.name}
                      required
                    />
                    {errors.name && <span className={Styles.errorMsg}>{errors.name}</span>}
                  </div>
                  <div className={Styles.group}>
                    <input
                      type="email"
                      placeholder="Email Id"
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                      required
                    />
                    {errors.email && <span className={Styles.errorMsg}>{errors.email}</span>}
                  </div>
                  <div className={Styles.group}>
                    <input
                      type="phone"
                      placeholder="Contact No"
                      name="contact"
                      onChange={handleChange}
                      value={formData.contact}
                      required
                    />
                    {errors.contact && <span className={Styles.errorMsg}>{errors.contact}</span>}
                  </div>
                </div>
                {/* <div className={Styles.eveningGroup}>
                  <div className={Styles.group}>
                    <textarea placeholder="Message"></textarea>
                  </div>
                </div> */}
                <div className={Styles.eveningGroup}>
                  <div className={Styles.group}>
                    <button type="button" onClick={handleSubmit}>
                      Submit <IoIosArrowRoundForward />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpcomingWebinarForm;
