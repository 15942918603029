import React, { useState, useEffect } from "react";
import Styles from '../Styles/Common.module.css';
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { IoMdClose } from "react-icons/io";
import constants from "../../services/constants";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
import { useLocation } from "react-router-dom";
import { getData } from "../../services/routes.services";
import parse from 'html-react-parser';
import Loader from "../Common/PageLoader/Loader";
import ReactPlayer from 'react-player/youtube';
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const dayWithSuffix = day + (day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th');
    return `${dayWithSuffix} ${month} ${year}`;
  };

function CommunityComponent() {
    const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    //console.log(currentMenuItem)
    const BannerImage = currentMenuItem ? currentMenuItem.images : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.title : 'Courses'; 
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [isPlaying, setIsPlaying] = useState(null);
    const [communityData, setCommunityData] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchRows = async () => {
        try {
            const response = await getData('community');
            const data = response.data;
            setCommunityData(data.communitys);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching rows:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchRows();
    }, []);
    console.log(communityData)
    // Image Size Loader
    if(loading){
        return <Loader />
    }
    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setImageDimensions({
            width: naturalWidth,
            height: naturalHeight,
        });
    };

    const PlayVideoHandler = (itemId) => {
        setIsPlaying(itemId);
        
    };
    const CloseVideoHandler = () => {
        setIsPlaying(null);
    };
    const getYouTubeID = (url) => {
        if (!url || typeof url !== "string") {
            return null;
        }
        const regExp = 
            /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return match && match[1].length === 11 ? match[1] : null;
    };
    const thumbnailImage = (item) => {
        const videoID = getYouTubeID(item.extralink);
        if (videoID) {
          return `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`;
        } else if (item.video) {
          return constants.Image_BASE_URL + item.images;
        }
        return null;
      };
    return (
        <div className={Styles.communityPage + " " + Styles.topSpace}>
            <CommonBanner bannerImg={constants.Image_BASE_URL+""+BannerImage} bannerTitle={BannerTitle}/>
            <BreadcrumbComponent home="Home" listOne="The Ananta Community" />

            <div className={Styles.communityBlock}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className={Styles.centerTitle}>
                                The <span>Ananta Community</span>
                            </h3>
                            <div className={Styles.communityContainer}>
                                {communityData &&
                                    communityData.map((item, index)=>{
                                        return(
                                            <div className={Styles.communityRow} key={index}>
                                                <div className={Styles.imageSide}>
                                                    <figure>
                                                    <img src={thumbnailImage(item)} alt={item.title} onLoad={handleImageLoad} width={imageDimensions.width} height={imageDimensions.height} />
                                                    </figure>
                                                    <button className={Styles.playBtn} onClick={()=>{PlayVideoHandler(item._id)}}>
                                                        <img alt="Play Icon" src={require('../../assest/images/video_icon2.png')} />
                                                    </button>
                                                    {isPlaying === item._id && (
                                                        <div className={Styles.communityVideo}>
                                                            <div className={Styles.videoCont}>
                                                                <button className={Styles.closeVideo} onClick={CloseVideoHandler}>
                                                                    <IoMdClose />
                                                                </button>
                                                                {item.extralink ? (
                                                                    <ReactPlayer
                                                                        url={item.extralink}
                                                                        playing={true}
                                                                        controls={true}
                                                                        width="100%"
                                                                        height="100%"
                                                                    />
                                                                ) : item.video ? (
                                                                    <video width="100%" height="100%" controls autoPlay>
                                                                        <source src={`${constants.Image_BASE_URL}${item.video}`} type="video/mp4"/>
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                ) : (
                                                                    <p>No video available</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={Styles.content}>
                                                    <span>{formatDate(item.date)}</span>
                                                    <h5>{item.title}</h5>
                                                    {parse(`${item.description}`)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default CommunityComponent;
