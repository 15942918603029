import React, { useState, useEffect } from "react";
import Styles from "../Styles/Common.module.css";
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import constants from "../../services/constants";
import { getOurTeam } from "../../services/routes.services";
import Loader from "../Common/PageLoader/Loader";
import { useSelector } from "react-redux";
import MenuPath from "../Common/Header/CurrentPath";
import { IoMdClose } from "react-icons/io";
import parse from "html-react-parser";

function OurTemePage() {
  const [teamData, setTeamData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOurTeam()
      .then((res) => {
        setTeamData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
  };

  const toggleContentHandler = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={Styles.coursesPage}>
      {/* <CommonBanner
        bannerImg={`${constants.Image_BASE_URL}/${BannerImage}`}
        bannerTitle={BannerTitle}
      />
      <div className={Styles.brownBg}>
        <BreadcrumbComponent home="Home" listOne="Our Teme" />
      </div> */}
      <div className={Styles.teameBlock}>
        <Container>
          <Row>
            <Col>
              <div className={Styles.heading}>
                <h3>
                  Our <span>Team</span>
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={Styles.teamContainer}>
                {teamData &&
                  teamData.map((item, index) => {
                    const isActive = activeIndex === index;
                    return (
                      <div className={Styles.teamElem} key={item.id || index}>
                        <figure>
                          <img
                            src={`${constants.Image_BASE_URL}/${item.imageUrl}`}
                            alt={item.name || "Team member"}
                            onLoad={handleImageLoad}
                            width={imageDimensions.width}
                            height={imageDimensions.height}
                          />
                        </figure>
                        <div className={Styles.content}>
                          <h5>{item.name}</h5>
                          <h6>{item.title}</h6>
                          <h4>{parse(item.position || "")}</h4>
                        </div>
                        <div className={Styles.teamBtn}>
                          <button type="button" onClick={() => toggleContentHandler(index)}><img src={require('../../assest/images/blue_btn.png')} /></button>
                        </div>
                        <div className={`${ isActive ? Styles.active : "" } ${Styles.moreContant}`}>
                          <button type="button" onClick={() => toggleContentHandler(index)}>
                            <IoMdClose />
                          </button>
                          {parse(item.description || "")}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default OurTemePage;
