import React, { useState, useEffect } from "react";
import Styles from "../Styles/Common.module.css";
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../Common/CommonComponents/CommonBanner";
import BreadcrumbComponent from "../Common/CommonComponents/Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { getData } from "../../services/routes.services";
import parse from "html-react-parser";
import constants from "../../services/constants";
import Loader from "../Common/PageLoader/Loader";
import { useSelector } from 'react-redux';
import MenuPath from "../Common/Header/CurrentPath";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);
};

function MediaComponent() {
  const location = useLocation();
    const { menu } = useSelector(state => state.header);
    const currentPageUrl = location.pathname;
    const currentMenuItem = MenuPath(menu, currentPageUrl);
    const BannerImage = currentMenuItem ? currentMenuItem.images : ''; 
    const BannerTitle = currentMenuItem ? currentMenuItem.title : ''; 
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  //Image Size Loader
  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
  };
  const fetchRows = async () => {
    try {
      const response = await getData("medias");
      const data = response.data;
      setBlogData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching rows:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRows();
  }, []);
  if(loading){
    return <Loader />
  }
  
  const itemsPerPage = 6; 
  const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = blogData.slice(indexOfFirstItem, indexOfLastItem);
const totalPages = Math.ceil(blogData.length / itemsPerPage);
const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
  window.scrollTo(0, 0);
};

  console.log(blogData);
  return (
    <div className={Styles.blogPage + " " + Styles.mediaPage + " " + Styles.topSpace}>
      <CommonBanner bannerImg={constants.Image_BASE_URL+""+BannerImage} bannerTitle={BannerTitle}/>
      <div className={Styles.brownBg}>
        <BreadcrumbComponent home="Home" listOne="Media" />
      </div>
      <div className={Styles.blogsBlock}>
        <Container>
          <Row>
            <Col>
              <h3 className={Styles.centerTitle}>
                <span>Media</span>
              </h3>
            </Col>
          </Row>
          <Row>
            {blogData &&
              blogData.map((item, index) => {
                return (
                  <Col md={4} key={index} className={Styles.bolgWidth}>
                    <div className={Styles.blogList}>
                      <figure>
                        <img
                          src={constants.Image_BASE_URL + "" + item.imageUrl}
                          alt={item.title}
                          onLoad={handleImageLoad}
                          width={imageDimensions.width}
                          height={imageDimensions.height}
                        />
                      </figure>
                      <div className={Styles.content}>
                        <h6>
                          {/* <Link
                            to={{ pathname: `/blogDetail/${item._id}` }}
                            state={{
                              title: item.title,
                              bannerImg: item.imageUrl,
                              date: item.createdAt,
                              content: item.content,
                            }}
                            title={item.title}
                          > */}
                          <a href={item.link} target="_blank" rel="noopener noreferrer" title={item.title}>
                            {item.title}
                          </a>
                        </h6>
                       
                        <div className={Styles.blogTime}>
                          <span>{formatDate(item.createdAt)}</span>
                          <a href={item.link} target="_blank" rel="noopener noreferrer" title={item.title}>
                            {/* <img
                              src={require("../../assest/images/share_icon.png")}
                              alt=""
                            /> */}
                            <IoIosArrowRoundForward />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
        
        <div className={Styles.courseBtn}>
          <div className={Styles.pagination}>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={currentPage === index + 1 ? Styles.active : Styles.pageActive}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      
      </div>
    </div>
  );
}

export default MediaComponent;
