import React, { useState } from "react";
import axios from "axios";
import constants from "../../services/constants";
import Style from "./Login.module.css"; // Import CSS file

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${constants.API_BASE_URL}auth/forgot-password`,
        { email }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || "Error sending reset email");
    }
  };

  return (
    <div className={Style.loginContainer}>
      <h4>Forgot Password</h4>
      <form onSubmit={handleSubmit}>
        <div className={Style.formGroup}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            className={Style.formInput}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit" className={Style.loginButton}>
          Send Reset Link
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ForgotPassword;
