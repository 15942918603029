import React, { useState, useEffect, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Form, Container, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getData,
  createData,
  UpdateData,
  deleteData,
} from "../../services/routes.services";
import constants from "../../services/constants";
import { AuthContext } from "../../context/AuthContext";
const AboutUs = () => {
  const [rows, setRows] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newOrder, setNewOrder] = useState("");
  const [isDataOpen, setIsDataOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [image, setImage] = useState(null); // Change to single image file

  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;
  const { user } = useContext(AuthContext);

  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`, // Assuming token is stored in `user.token`
    },
  };

  useEffect(() => {
    fetchRows();
  }, []);

  const fetchRows = async () => {
    try {
      const response = await getData("aboutus");
      const data = response.data.about;
      setRows(data);
    } catch (error) {
      console.error("Error fetching rows:", error);
    }
  };

  function truncateText(text, maxLength) {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }
  const handleAddRow = async (e) => {
    e.preventDefault();
    if (newItem && newDescription) {
      const formData = new FormData();
      formData.append("name", newItem);
      formData.append("description", newDescription);
      formData.append("order", newOrder);
      if (image) {
        formData.append("images", image); // Append single image file
      }

      try {
        if (editIndex !== null) {
          await UpdateData(`aboutus/${editIndex._id}`, formData, config);
          toast.success("Row updated successfully!");
          fetchRows();
        } else {
          await createData("aboutus", formData, config);
          toast.success("Row added successfully!");
          fetchRows();
        }
        resetForm();
      } catch (error) {
        console.error("Error saving row:", error);
        toast.error("Error saving row!");
      }
    }
  };

  const resetForm = () => {
    setNewItem("");
    setNewDescription("");
    setNewOrder("");
    setImage(null);
    setEditIndex(null);
    setIsDataOpen(false);
  };

  const handleEditRow = (index) => {
    const row = index;
    setNewItem(row.name);
    setNewDescription(row.description);
    setNewOrder(row.order);
    setImage(row.images); // Adjust if 'image' is a URL or file
    setEditIndex(index);
    setIsDataOpen(true);
  };

  const handleClose = () => {
    resetForm();
  };

  const handleDeleteRow = async (row) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${row.name}"?`
    );
    if (confirmDelete) {
      try {
        await deleteData(`aboutus/${row._id}`, config);
        toast.success("Row deleted successfully!");
        fetchRows();
      } catch (error) {
        console.error("Error deleting row:", error);
        toast.error("Error deleting row!");
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * rowsPerPage;
  const currentPageData = rows.slice(offset, offset + rowsPerPage);
  const pageCount = Math.ceil(rows.length / rowsPerPage);

  return (
    <Container className="mt-4">
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setIsDataOpen(true)}
      >
        Add Entry
      </Button>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />

      <Modal show={isDataOpen} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {editIndex !== null ? "Edit Entry" : "Add New Entry"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddRow}>
            <Form.Group className="mb-3">
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Order:</Form.Label>
              <Form.Control
                type="number"
                value={newOrder}
                onChange={(e) => setNewOrder(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description:</Form.Label>
              <ReactQuill
                theme="snow"
                value={newDescription}
                onChange={setNewDescription}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Upload Image:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
              {image && (
                <img
                  src={`${constants.Image_BASE_URL}${image}`}
                  alt="Preview"
                  style={{ width: "20%", marginTop: "10px" }}
                />
              )}
            </Form.Group>

            <Button type="submit" variant="success">
              {editIndex !== null ? "Update" : "Submit"}
            </Button>
            <Button variant="secondary" onClick={handleClose} className="ms-2">
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>#</th>
            <th style={{ width: "20%" }}>Title</th>
            <th style={{ width: "45%" }}>Description</th>
            <th style={{ width: "30%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((row, index) => (
            <tr key={index}>
              <td>{offset + index + 1}</td>
              <td>{row.name}</td>
              <td
                dangerouslySetInnerHTML={{
                  __html: truncateText(row.description, 100),
                }}
              ></td>
              <td>
                <Button variant="warning" onClick={() => handleEditRow(row)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteRow(row)}
                  className="ms-2"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {rows.length > rowsPerPage && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      )}
    </Container>
  );
};

export default AboutUs;
